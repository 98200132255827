import L from 'leaflet'
import 'leaflet-imageoverlay-rotated'
import 'leaflet-toolbar'
import React, { useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import MapControls from '../../../map/MapControls'
import GenericPage from '../../components/generic/GenericPage'
import tiles from '../../components/generic/Tiles'

const MapPosts = () => {
  const [mapType, setMap] = useState('https://tile.openstreetmap.org/{z}/{x}/{y}.png')

  const { t, i18n } = useTranslation()

  const [isLoading, setIsLoading] = useState(false)

  const mapRef = useRef(null)
  const paneRef = useRef(null)
  const drawnLayerRef = useRef(null)
  const showLayerRef = useRef(null)
  const tileLayerRef = useRef(null)
  const searchGeometryRef = useRef(null)

  let map

  useEffect(() => {
    try {
      map = L.map('map', {
        center: [49.237257, 69.9804824],
        zoom: 5,
        minZoom: 1,
        zoomControl: false,
        attributionControl: false
      })
      L.tileLayer(mapType, {
        subdomains: ['mt0', 'mt1', 'mt2', 'mt3'],
        maxZoom: 18,
        id: 'baselayer'
      }).addTo(map)
      L.control.attribution({
        position: 'bottomright'
      }).addTo(map)

      searchGeometryRef.current = L.featureGroup().addTo(map)
      showLayerRef.current = L.featureGroup().addTo(map)
      drawnLayerRef.current = L.featureGroup().addTo(map)
      tileLayerRef.current = L.featureGroup().addTo(map)

      mapRef.current = map
      const topPane = mapRef?.current.createPane('leaflet-top-pane', mapRef.current.getPanes().mapPane)
      paneRef.current = topPane

      MapControls(mapRef.current, t)
      mapRef.current.invalidateSize(9)
    } catch (e) {

    }
  }, [])
  useEffect(() => {
    mapRef.current.attributionControl.setPrefix('')
    L.tileLayer(mapType, {
      maxZoom: 18,
      id: 'baselayer'
    }).addTo(mapRef.current)
  }, [mapType])
  useEffect(() => {
    searchGeometryRef.current.clearLayers()
    fetch('../points_hydro_new.json').then(async (response) => {
      const json = await response.json()
      json.forEach((f) => {
        const mask = L.geoJSON({
          type: 'Feature',
          geometry: {
            type: 'Point',
            coordinates: [f.x, f.y]
          }
        })
        console.log(f)
        console.log(f.Farea)
        const name = `
        <b>${f.name}</b><br>
        Открыт: ${f.opened},<br>
        ${f.closed && 'Закрыт: ' + f.closed + ',<br>'}
        <b>Площадь водосбора</b><br>
        F: ${f.Farea} км<sup>2</sup>,<br>
        Hср: ${f.Harea} м,<br>
        <b>Изученность</b><br>
        Годы наблюдений: ${f.years},<br>
        Всего лет наблюдений: ${f.t_years}.<br>
        `

        mask.bindTooltip(name)

        mask.addEventListener('mouseover', () => (mask.openTooltip()))

        searchGeometryRef.current.addLayer(mask)
      })
    })
  }, [])

  return (
    <GenericPage mapRef={mapRef} title="Гидропосты" tiles={tiles.back('db-hydro')} />
  )
}
export default MapPosts
