import * as FA from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom/cjs/react-router-dom'
import { Button } from 'rsuite'

const Recs3 = () => {
  const { t, i18n } = useTranslation()

  return (
    <div>
      <Link to="/recs">
        <Button appearance="primary">
          <FontAwesomeIcon icon={FA.faHome} />
        </Button>
      </Link>
      <h1 className="title-h1">Мероприятия</h1>
      <hr />
      <div className="about-text">
        <p>
          <b>Мероприятия для ПСС земледельческого использования.</b>
        </p>
        <p>1-Почвозащитные севообороты и обработка почв.</p>
        <p>2-Мероприятия по борьбе с водной эрозией.</p>
        <p>3-Противодефляционные мероприятия</p>
        <p>4-Мероприятия по борьбе с засолением</p>
        <p>5-Защитные лесные полосы.</p>
        <p>6-Охрана защитных лесонасаждений.</p>
        <p>7-Проведение мелиоративных мероприятий</p>
        <p>
          8-Рекультивация и реабилитация нарушенных природно-территориальных
          комплексов земледельческого использования.
        </p>
        <p>9-Организация мониторинга за очагами деградационных процессов.</p>
        <p>10-Фитосанитарная оптимизация.</p>
        <p>
          <b>Мероприятия для ПСС пастбищного использования.</b>
        </p>
        <p>1-Организация территории культурного пастбища (загонного).</p>
        <p>
          2-Развитие отгонного животноводства. Регулирование выпаса –
          пастбищеобороты, нормирование пастбищных нагрузок.
        </p>
        <p>
          3-Запрет выпаса на отдельных (локальных) участках от 3 до 5 лет с
          одновременным подсевом кормовых культур.
        </p>
        <p>4-Запрет вырубки кустарников саксаула на песчаных пастбищах.</p>
        <p>5-Поверхностное улучшение пастбищ.</p>
        <p>6-Создание пастбищезащитных полос.</p>
        <p>7-Борьба с сорной и ядовитой растительностью.</p>
        <p>8-Создание сеяных пастбищ, коренное улучшение.</p>
        <p>9-Технология создания искусственных пастбищных фитоценозов.</p>
        <p>10-Фитомелиорация.</p>
        <p>11-Мероприятия по обводнению пастбищ.</p>
        <p>
          12-Мероприятия по снижению загрязнения пастбищ сточными водами и
          отходами с животноводческих комплексов.
        </p>
        <p>13-Создание заказников семенного фонда.</p>
        <p>
          <b>Мероприятия для ПСС сенокосного использования.</b>
        </p>
        <p>1-Инвентаризация сенокосных угодий.</p>
        <p>2-Ранжирование норм и сроков сенокошения.</p>
        <p>
          3-Комплекс агротехнических, агрохимических и агромелиоративных
          мероприятий.
        </p>
        <p>4-Запрет сенокошения деградированных сенокосов.</p>
        <p>6-Создание культурных (сеяных) сенокосных угодий.</p>
        <p>
          7-Борьба с непоедаемыми (сорными, вредными и ядовитыми) растениями.
        </p>
        <p>
          <b>Мероприятия для лесных ландшафтов.</b>
        </p>
        <p>1-Организация рационального лесопользования и лесоустройства.</p>
        <p>2-Мероприятия по лесоразведению и воспроизводству лесов.</p>
        <p>3-Мероприятия по понижению пожарной опасности лесов.</p>
        <p>
          5-Мероприятия по нормированию и регулированию рекреационных нагрузок в
          лесных ландшафтах и формированию аттрактивных ландшафтов.
        </p>

        <table cellPadding="7" cellSpacing="0">
          <col />
          <col />
          <tr valign="top">
            <td>
              <p>Номерация</p>
            </td>
            <td>
              <p>
                Направление деятельности, виды&nbsp; и содержание мероприятий
              </p>
            </td>
          </tr>
          <tr valign="top">
            <td>
              <p lang="ru-RU">
                <br />
              </p>
            </td>
            <td>
              <p>
                <b>Охрана и рациональное использование пастбищ и сенокосов</b>
              </p>
            </td>
          </tr>
          <tr valign="top">
            <td>
              <p>
                <b>1-1</b>
              </p>
            </td>
            <td>
              <p>
                Регулирование выпаса должно учитывать ландшафтные характеристики
                отдельных участков, тип пастбищеоборота и нормирование нагрузок
                с учётом сезонного использования пастбищ и их продуктивности.
                Для глинистых пастбищ предпочтительной является схема
                пастбищеоборота с ротацией 4-5 лет. Для песчаных массивов
                оптимальным вариантом является участковый выпас скота, который
                более экономичен по сравнению с загонным. Рекомендуется
                чередовать режимы осенне-зимнего, зимнего и зимне-весеннего
                использования по годам. В некоторых случаях попеременное
                сенокосно-пастбищное использование также приносит хорошие
                результаты. Ранневесенний выпас скота с последующим сенокошением
                недопустим.
              </p>
            </td>
          </tr>
          <tr valign="top">
            <td>
              <p>
                <b>1-2</b>
              </p>
            </td>
            <td>
              <p>Экологическое зонирование земель пастбищного назначения.</p>
            </td>
          </tr>
          <tr valign="top">
            <td>
              <p>
                <b>1-3</b>
              </p>
            </td>
            <td>
              <p>
                Экологическая оценка состояния приаульных пастбищ для
                определения «глубины» и способов их рекультивации.
              </p>
            </td>
          </tr>
          <tr valign="top">
            <td>
              <p>
                <b>1-4</b>
              </p>
            </td>
            <td>
              <p>
                Мероприятия по обводнению пастбищ рекомендуются для всех
                природно-территориальных комплексов, где отсутствуют
                естественные водные источники и предусматривают реконструкцию
                старых и обустройство новых колодцев, скважин, использование вод
                подтакырных линз, а при отсутствии естественных водопоев,
                обеспечение подвоза воды на животноводческие фермы и организация
                временных мест водопоя.
              </p>
            </td>
          </tr>
          <tr valign="top">
            <td>
              <p>
                <b>1-5</b>
              </p>
            </td>
            <td>
              <p>
                Выборочное сенокошение рекомендовано в пределах природных
                комплексов аллювиальной, аллювиально-пролювиальной и
                делювиально-пролювиальной равнин с учётом сезона и
                агроклиматических условий текущего года.
              </p>
            </td>
          </tr>
          <tr valign="top">
            <td>
              <p>
                <b>1-6</b>
              </p>
            </td>
            <td>
              <p>
                Фитомелиоративные мероприятия с целью повышения продуктивности
                пастбищ и сенокосов методом полукоренного (полосного) улучшения,
                заключающемся в чередовании распаханных полос и целинных с
                естественной растительностью; на суглинистых и легкосуглинистых
                почвах рекомендуются для введения в культуру еркек, изень,
                терескен, кейреук; для песков - подсев бороздами; необходима так
                же подсадка видов-пескоукрепителей (жузгун, астрагалы, саксаул и
                др.).
              </p>
            </td>
          </tr>
          <tr valign="top">
            <td>
              <p>
                <b>1-7</b>
              </p>
            </td>
            <td>
              <p>
                Коренное улучшение и рекультивация для природных комплексов,
                используемых как сенокосы и пастбища, направлены на улучшение
                общего ландшафтно-экологического состояния земель и включают
                перепланировку угодий, выравнивание и расчистку поверхности (от
                кустарников, камней, мусора, уничтожение кочек), внесение
                удобрений, улучшение и регулирование водного режима почв,
                рассоление верхнего горизонта.
              </p>
            </td>
          </tr>
          <tr valign="top">
            <td>
              <p lang="ru-RU">
                <br />
              </p>
            </td>
            <td>
              <p>
                <b>Мероприятия по улучшению состояния пашни</b>
              </p>
            </td>
          </tr>
          <tr valign="top">
            <td>
              <p>
                <b>2-1</b>
              </p>
            </td>
            <td>
              <p>
                Агрономические мероприятия для обрабатываемых земель –
                увеличение глубины вспашки, изменение водно-физических свойств и
                окультуривание пашни (увеличение мощности пахотного слоя,
                обогащение органическими веществами, известкование, гипсование),
                плоскорезная и безотвальная обработка, полосное размещение пара
                и пропашных культур, мульчивание.
              </p>
            </td>
          </tr>
          <tr valign="top">
            <td>
              <p>
                <b>2-2</b>
              </p>
            </td>
            <td>
              <p>
                Организационно-хозяйственные мероприятия и рекультивация
                рекомендуются для земель длительного сельскохозяйственного
                использования подразумевают перепланировку орошаемых угодий
                (изменение размеров, увеличение ширины, уменьшение длины,
                ориентировка полей перпендикулярно направлению господствующих
                ветров и склонов).
              </p>
            </td>
          </tr>
          <tr valign="top">
            <td>
              <p>
                <b>2-3</b>
              </p>
            </td>
            <td>
              <p>
                Дифференцированное применение органических и минеральных
                удобрений с учетом степени деградации пахотных земель.
              </p>
            </td>
          </tr>
          <tr valign="top">
            <td>
              <p>
                <b>2-4</b>
              </p>
            </td>
            <td>
              <p>
                Расширение посевных площадей кормовых и овощебахчевых культур.
              </p>
            </td>
          </tr>
          <tr valign="top">
            <td>
              <p>
                <b>2-5</b>
              </p>
            </td>
            <td>
              <p>
                Введение почвозащитных и водосберегающих технологий полива, его
                качественное улучшение, осуществление противоэрозионной техники
                полива (полив по наименьшему уклону, контурное орошение, полив
                переменным расходом струи в борозду, применение дождевальных
                установок, реконструкция оросительной сети с использованием
                полиэтиленовых труб).
              </p>
            </td>
          </tr>
          <tr valign="top">
            <td>
              <p>
                <b>2-6</b>
              </p>
            </td>
            <td>
              <p>
                Рекультивация нарушенных земель, восстановление и повышение их
                плодородия и других полезных свойств (внесение органических и
                минеральных удобрений, рассоление верхнего горизонта почвы,
                перепланировка и нивелировка поверхности пашни, своевременное
                вовлечение земель в хозяйственный оборот).
              </p>
            </td>
          </tr>
          <tr valign="top">
            <td>
              <p lang="ru-RU">
                <br />
              </p>
            </td>
            <td>
              <p>
                <b>
                  Охрана лесов и травянистой растительности; фитомелиоративные
                  мероприятия
                </b>
              </p>
            </td>
          </tr>
          <tr valign="top">
            <td>
              <p>
                <b>3-1</b>
              </p>
            </td>
            <td>
              <p>
                Ограничение вырубки кустарников (саксаула, тамариска, жузгуна, и
                т.д.) и выкорчевки их на топливо и корм скоту, приводящих к
                формированию умеренно и сильно деградированных геосистем с
                развитием процессов ксерофитизации и иссушения; в геосистемах
                эоловой равнины способствует площадной и локальной дефляции; в
                геосистемах делювиально-пролювиальной равнины - развитию
                плоскостного смыва.
              </p>
            </td>
          </tr>
          <tr valign="top">
            <td>
              <p>
                <b>3-2</b>
              </p>
            </td>
            <td>
              <p>
                Запрет вырубки пустынных лесов; разработка и реализация
                организационно-правовой ответственности юридических/физических
                лиц за причиненный ущерб лесному фонду; запрет
                несанкционированные рубки, выдачу лицензий на обустройство
                спортивных и жилищных комплексов, регламент туристической
                деятельности, сбора лекарственных растений.
              </p>
            </td>
          </tr>
          <tr valign="top">
            <td>
              <p>
                <b>3-3</b>
              </p>
            </td>
            <td>
              <p>
                Лесовозобновление и создание условий для повышения
                продуктивности пустынных (саксауловых) и горных (можжевеловых)
                лесов путем подсадки для борьбы с сокращением лесопокрытых
                площадей и восстановления биологического разнообразия в районах:
                Мойынкумский, Шуский, Таласский, Меркенский; слабое
                возобновление растительности связано с прекращением самосева,
                уничтожением подроста, разрушением почвенно-растительного
                покрова при прокладке дорог, трубопроводов, неорганизованном
                движении транспорта; возможен ввод почвоулучшающих древесных,
                кустарниковых и травянистых видов растений, использование
                удобрений, рыхление почвы, временный полив, охрана молодых
                деревьев.
              </p>
            </td>
          </tr>
          <tr valign="top">
            <td>
              <p>
                <b>3-4</b>
              </p>
            </td>
            <td>
              <p>
                Лесовосстановление в пустынной зоне путем посева семян
                пескоукрепляющих пород на площади около 2300 га в районах:
                Жуалынском, Байзакском, Сарысуском, Таласском.
              </p>
            </td>
          </tr>
          <tr valign="top">
            <td>
              <p>
                <b>3-5</b>
              </p>
            </td>
            <td>
              <p>
                Создание производственных лесных питомников для выращивания
                чистосортного посадочного материала для реализации проекта
                Правительства РК по сохранению и восстановлению генофонда горных
                диких плодовых растений (яблоня, абрикос, орех, ягодные
                культуры) в районе Т.Рыскулова.
              </p>
            </td>
          </tr>
          <tr valign="top">
            <td>
              <p>
                <b>3-6</b>
              </p>
            </td>
            <td>
              <p>
                Закладка плантаций древесно-кустарниковых растений (тополь, вяз,
                гребенщик и пр.) для целей сохранения естественных лесов и
                обеспечения населения альтернативными видами топлива в районах:
                Байзакский, Меркенский, Т.Рыскулова, Жамбылский.
              </p>
            </td>
          </tr>
          <tr valign="top">
            <td>
              <p>
                <b>3-7</b>
              </p>
            </td>
            <td>
              <p>
                Ремонт и установка противопожарных, наблюдательных вышек и
                контрольных постов на особоохраняемых природных территориях
                (Андасайский, Беркаринский, Каракунузский ООПТ).
              </p>
            </td>
          </tr>
          <tr valign="top">
            <td>
              <p>
                <b>3-8</b>
              </p>
            </td>
            <td>
              <p>
                Реабилитация территорий после лесных пожаров и выгорания
                травянистой растительности; борьба с пожарами: предупредительная
                (противопожарная пропаганда среди населения, борьба с
                захламленностью), дозорно-сторожевая (обходы, наблюдения, что
                особенно важно в слабозаселенных районах) и борьба с огнём.
              </p>
            </td>
          </tr>
          <tr valign="top">
            <td>
              <p>
                <b>3-9</b>
              </p>
            </td>
            <td>
              <p>
                Создание санитарно-защитных и водоохранных зон рекомендуется
                вокруг гидрообъектов и пашни, вдоль грунтовых и шоссейных дорог;
                в зоне слабой периодической загазованности большее количество
                серы поглощают листья тополя, ясеня, сирени, жимолости, меньше -
                вяза, клена; можно рекомендовать создание лесополос из черного
                саксаула, тамариска, чингила, которые располагаются
                перпендикулярно ветрам преобладающих румбов и начинают выполнять
                свои функции через 2-3 года после посадки.
              </p>
            </td>
          </tr>
          <tr valign="top">
            <td>
              <p>
                <b>3-10</b>
              </p>
            </td>
            <td>
              <p>
                Защита древесно-кустарниковой растительности от вредителей и
                болезней путем лесохозяйственных мероприятий (подбор здорового
                посадочного и посевного материала, поддержание насаждений в
                здоровом состоянии удалением зараженных и больных деревьев,
                уборка ветоши), физико-механических (сбор и уничтожение
                вредителей ловушками, приманками, заградительными канавками) и
                химических (ядохимикаты или пестициды) методов борьбы в районах:
                Байзакский,&nbsp; Т. Рыскулова, Кордайский, Жуалынский.
              </p>
            </td>
          </tr>
          <tr valign="top">
            <td>
              <p>
                <b>3- 11</b>
              </p>
            </td>
            <td>
              <p>
                Резервирование земель для лесоустроительных и лесохозяйственных
                целей; закладка и расширение постоянных питомников по
                выращиванию посадочного материала древесно-кустарниковых
                лиственных и хвойных пород для озеленения населенных пунктов
                области и уход за питомниками.
              </p>
            </td>
          </tr>
          <tr valign="top">
            <td>
              <p>
                <b>3-12</b>
              </p>
            </td>
            <td>
              <p>
                Мероприятия по озеленению территории вокруг населенных пунктов
                путем закладки плантаций лиственных пород в г. Тараз, Байзакском
                и Жамбылском районах.
              </p>
            </td>
          </tr>
          <tr valign="top">
            <td>
              <p>
                <b>3-13</b>
              </p>
            </td>
            <td>
              <p>
                Составление кадастра редких и полезных видов местной флоры
                Жамбылской области и разработка рекомендаций по охране и
                рациональному использованию растительных ресурсов.
              </p>
            </td>
          </tr>
          <tr valign="top">
            <td>
              <p lang="ru-RU">
                <br />
              </p>
            </td>
            <td>
              <p>
                <b>
                  Мероприятия по охране и рациональному использованию водных
                  ресурсов
                </b>
              </p>
            </td>
          </tr>
          <tr valign="top">
            <td>
              <p>
                <b>4- 1</b>
              </p>
            </td>
            <td>
              <p>
                Усилить контроль за соблюдением «Концепции развития системы
                управления водными ресурсами Республики Казахстан на 2024 – 2030
                годы» и отраслевой программы «Питьевые воды».
              </p>
            </td>
          </tr>
          <tr valign="top">
            <td>
              <p>
                <b>4-2</b>
              </p>
            </td>
            <td>
              <p>
                Инвентаризация и качественная оценка текущей и перспективной
                потребности в воде для хозяйственно-питьевого водоснабжения;
                контроль за соблюдением утвержденного лимита водопотребления.
              </p>
            </td>
          </tr>
          <tr valign="top">
            <td>
              <p>
                <b>4-3</b>
              </p>
            </td>
            <td>
              <p>
                Инвентаризация и качественная оценка (ПДК) состояния
                поверхностных (реки, озера, водохранилища) водных источников по
                соответствию нормам использования в питьевом водоснабжении и
                различных сферах производства.
              </p>
            </td>
          </tr>
          <tr valign="top">
            <td>
              <p>
                <b>4-4</b>
              </p>
            </td>
            <td>
              <p>
                Инвентаризация и качественная оценка (ПДК) состояния подземных
                водных источников (скважины, колодцы) по соответствию нормам
                использования в различных сферах производства и питьевого
                водоснабжения; качественная переоценка запасов подземных вод по
                месторождениям.
              </p>
            </td>
          </tr>
          <tr valign="top">
            <td>
              <p>
                <b>4-5</b>
              </p>
            </td>
            <td>
              <p>
                Ремонт коллекторно-дренажной сети; ремонтно-восстановительные
                работы на водохранилищах
              </p>
            </td>
          </tr>
          <tr valign="top">
            <td>
              <p>
                <b>4-6</b>
              </p>
            </td>
            <td>
              <p>
                Строительство новых полей фильтрации для отведения сточных вод и
                восстановление дренажных каналов
              </p>
            </td>
          </tr>
          <tr valign="top">
            <td>
              <p>
                <b>4-7</b>
              </p>
            </td>
            <td>
              <p>
                Внедрение, реконструкция и модернизация систем оборотного и
                бессточного водоснабжения всех видов для повторного
                использования и очистки вод, улучшения их качества, не
                вызывающих побочного негативного воздействия на другие природные
                среды и объекты: улавливание грубодисперсных (решетками, ситами,
                песколовками, септиками, навозоуловителями различных
                конструкций, аккумулирующие емкости, отстойники, сооружения и
                устройства для аэрации вод, биологические инженерные очистные
                сооружения, биологические каналы, экраны) и поверхностных
                (бензомаслоуловителями, отстойниками и др.) загрязнителей;
                ревизия и ремонт запорной арматуры на трубопроводах;
                антикоррозийная защита внутренней поверхности резервуаров и
                емкостей; строительство трубопроводов из коррозионностойких труб
                и катодная защита нефтепроводов.
              </p>
            </td>
          </tr>
          <tr valign="top">
            <td>
              <p>
                <b>4-8</b>
              </p>
            </td>
            <td>
              <p>
                Оптимизация сети накопителей промышленных стоков и отходов
                (шламонакопителей, отстойников, испарителей и т.д.), а также
                подземных хранилищ нефтепродуктов, газов и т.д.
              </p>
            </td>
          </tr>
          <tr valign="top">
            <td>
              <p>
                <b>4-9</b>
              </p>
            </td>
            <td>
              <p>
                Проведение мероприятий, направленных на ликвидацию последствий
                влияния накопителей стоков и отходов на прилегающие ПТК;
                строительство, реконструкция и восстановление дренажных систем
                полей фильтрации, накопителей сточных вод, золоотвалов,
                шламохранилищ и др.;&nbsp;
              </p>
            </td>
          </tr>
          <tr valign="top">
            <td>
              <p>
                <b>4-10</b>
              </p>
            </td>
            <td>
              <p>
                Мероприятия по приведению бесхозных мест размещения и
                захоронения отходов вблизи гидрообъектов в соответствие
                экологическим и санитарным требованиям по их обустройству;
                создание специальных защитных сооружений: противофильтрационные
                стенки, завесы, экраны, гидравлические завесы, вертикальные и
                горизонтальные дрены.
              </p>
            </td>
          </tr>
          <tr valign="top">
            <td>
              <p>
                <b>4-11</b>
              </p>
            </td>
            <td>
              <p>
                Модернизация и внедрение автоматизированных систем контроля
                качества, состава и объемов сброса сточных вод; приобретение и
                оснащение лабораторий по контролю за загрязнением сточных и
                природных вод; оборудование и модернизация на объектах
                химического и др. производств наблюдательной сети скважин для
                контроля за качеством подземных вод.
              </p>
            </td>
          </tr>
          <tr valign="top">
            <td>
              <p>
                <b>4-12</b>
              </p>
            </td>
            <td>
              <p>
                Разработка и внедрение комплекса мероприятий по охране
                поверхностных и подземных вод (предотвращение аварийных сбросов
                в водные объекты и рельеф местности; восстановление и
                поддержание благоприятного гидрологического режима и
                санитарно-экологического состояния на территории водоохранных
                полос и зон; расчистка русла реки или ложа водоема,
                предотвращение заиливания и загрязнения водного объекта;
                предотвращение сброса, удаление и обезвреживание возвратных
                сточных вод; очистка производственных сточных вод совместно с
                дождевыми и талыми водами, отводимыми с территории предприятия с
                целью использования их для технического водоснабжения;
                использование очищенных сточных вод для орошения
                древесно-кустарниковой растительности; санитарно-экологические
                попуски воды для повышения водности и поддержания экологического
                равновесия гидрообъектов).
              </p>
            </td>
          </tr>
          <tr valign="top">
            <td>
              <p>
                <b>4-13</b>
              </p>
            </td>
            <td>
              <p>
                Ликвидация или перевод на краново-регулируемый режим
                самоизливающихся бесхозных скважин.
              </p>
            </td>
          </tr>
          <tr valign="top">
            <td>
              <p>
                <b>4-14</b>
              </p>
            </td>
            <td>
              <p>
                Строительство водоводов питьевого водоснабжения до выявленных
                пунктов, использующих для питьевого водоснабжения привозную или
                некондиционную воду; строительство локальных водоводов с
                компактными сооружениями для очистки вод.
              </p>
            </td>
          </tr>
          <tr valign="top">
            <td>
              <p>
                <b>4-15</b>
              </p>
            </td>
            <td>
              <p>
                Контроль за качеством воды поступающей по водоводу или перевод
                сельского водоснабжения посредством водопровода на использование
                имеющихся месторождений подземных вод (учитывая даже
                высокоминерализованные, но поддающиеся опреснению) в тех
                населенных пунктах, где они имеются.
              </p>
            </td>
          </tr>
          <tr valign="top">
            <td>
              <p>
                <b>4-16</b>
              </p>
            </td>
            <td>
              <p>
                Реконструкция, мехочистка, восстановление каналов и
                гидросооружений; повышение энерговооруженности эксплуатационной
                службы системы (приобретение сцецтехники)
              </p>
            </td>
          </tr>
          <tr valign="top">
            <td>
              <p>
                <b>4-17</b>
              </p>
            </td>
            <td>
              <p>
                Разработка проектной документации по установлению водоохранных
                зон и полос р.Талас, Асса, Шу (до Фурмановского гидроузла).
              </p>
            </td>
          </tr>
          <tr valign="top">
            <td>
              <p lang="ru-RU">
                <br />
              </p>
            </td>
            <td>
              <p>
                <b>
                  Мероприятия по предупреждению загрязнения приземного
                  атмосферного воздуха
                </b>
              </p>
            </td>
          </tr>
          <tr valign="top">
            <td>
              <p>
                <b>5-1</b>
              </p>
            </td>
            <td>
              <p>
                Инвентаризация и обновление данных об организованных и
                неорганизованных источниках пылегазовыделений, улучшение условий
                рассеивания.
              </p>
            </td>
          </tr>
          <tr valign="top">
            <td>
              <p>
                <b>5-2</b>
              </p>
            </td>
            <td>
              <p>
                Проведение мероприятий по пылеподавлению на объектах
                недропользования, в т.ч. хвостохранилищах, шламонакопителях,
                карьерах, внутрихозяйственных дорогах.
              </p>
            </td>
          </tr>
          <tr valign="top">
            <td>
              <p>
                <b>5-3</b>
              </p>
            </td>
            <td>
              <p>
                Выполнение мероприятий по недопущению в процессе строительства
                скважин и проведения ремонтных работ открытого газонефтяного
                фонтанирования.
              </p>
            </td>
          </tr>
          <tr valign="top">
            <td>
              <p>
                <b>5-4</b>
              </p>
            </td>
            <td>
              <p>
                Модернизация и внедрение автоматизированных систем контроля за
                загрязнением атмосферного воздуха, оснащение стационарных
                источников выбросов вредных веществ в воздушный бассейн
                приборами контроля, строительство, приобретение и оснащение
                лабораторий по контролю за загрязнением атмосферного воздуха;
                контроль за своевременным проведением госповерки
                приборов-газоанализаторов и дымомеров.
              </p>
            </td>
          </tr>
          <tr valign="top">
            <td>
              <p>
                <b>5-5</b>
              </p>
            </td>
            <td>
              <p>
                Введение запрета на использование земель в сельскохозяйственном
                обороте вблизи от источников выбросов.&nbsp;
              </p>
            </td>
          </tr>
          <tr valign="top">
            <td>
              <p>
                <b>5-6</b>
              </p>
            </td>
            <td>
              <p>
                Выбор наиболее оптимального размещения вновь возводимых
                загрязняющих окружающую среду объектов на территориях,
                отдаленных от мест проживания населения.
              </p>
            </td>
          </tr>
          <tr valign="top">
            <td>
              <p>
                <b>5-7</b>
              </p>
            </td>
            <td>
              <p>
                Проектирование, строительство и обустройство санитарно-защитных
                зон для поглощения и отсорбирования ингредиентов-загрязнителей.
              </p>
            </td>
          </tr>
          <tr valign="top">
            <td>
              <p lang="ru-RU">
                <br />
              </p>
            </td>
            <td>
              <p>
                <b>
                  Мероприятия по предупреждению и ликвидации загрязнения
                  почвенного покрова
                </b>
              </p>
            </td>
          </tr>
          <tr valign="top">
            <td>
              <p>
                <b>6-1</b>
              </p>
            </td>
            <td>
              <p>
                Предупреждение грубых нарушений научно-обоснованной технологии
                хранения и транспортировки материалов и веществ.
              </p>
            </td>
          </tr>
          <tr valign="top">
            <td>
              <p>
                <b>6-2</b>
              </p>
            </td>
            <td>
              <p>
                Инвентаризация и качественная оценка влияния существующих
                транспортных коммуникаций на загрязнение почвогрунтов.
              </p>
            </td>
          </tr>
          <tr valign="top">
            <td>
              <p>
                <b>6-3</b>
              </p>
            </td>
            <td>
              <p>
                Строительство мусоросортировочного, мусороперерабатывающего и
                мусоросжигательного завода и оборудованного полигона для
                захоронения ТБО (в городах и районных центрах).
              </p>
            </td>
          </tr>
          <tr valign="top">
            <td>
              <p>
                <b>6-4</b>
              </p>
            </td>
            <td>
              <p>
                Строительство полигона для захоронения непригодных ядохимикатов,
                пестицидов и тоскичных отходов (г.Тараз).
              </p>
            </td>
          </tr>
          <tr valign="top">
            <td>
              <p>
                <b>6-5</b>
              </p>
            </td>
            <td>
              <p>
                Организация централизованного вывоза ИИИ (источников
                ионизирующего излучения) с истекшим сроком службы, принадлежащим
                бюджетным организациям.
              </p>
            </td>
          </tr>
          <tr valign="top">
            <td>
              <p>
                <b>6-6</b>
              </p>
            </td>
            <td>
              <p>
                Организация в г.Тараз пункта по сбору и централизованному вывозу
                ртутьсодержащих приборов и изделий.
              </p>
            </td>
          </tr>
          <tr valign="top">
            <td>
              <p>
                <b>6-7</b>
              </p>
            </td>
            <td>
              <p>
                Мероприятия по приведению бесхозных объектов размещения и
                захоронения отходов в соответствие экологическим и санитарным
                требованиям по их обустройству; сокращение площадей отчужденных
                земель за счет снижения расхода земли на обустройство.
              </p>
            </td>
          </tr>
          <tr valign="top">
            <td>
              <p>
                <b>6-8</b>
              </p>
            </td>
            <td>
              <p>Мониторинг за состоянием и ремонт газопроводов.</p>
            </td>
          </tr>
          <tr valign="top">
            <td>
              <p>
                <b>6-9</b>
              </p>
            </td>
            <td>
              <p>
                Комплекс мероприятий по рекультивации после загрязнения почв
                включает: активизацию абиотических физико-химических процессов и
                стимуляцию почвенной микрофлоры и фитомелиорация.
                <u>Подготовительный этап</u>: расчистка участка от усыхающей и
                мертвой растительности, завалов из строительного и бытового
                мусора, обваловка со стороны возможного повторного загрязнения
                почв, при условии, если она не была сделана в ходе ликвидации
                аварии. Не допускается выжигание и засыпание пятен загрязнения
                песком.
                <u>Агротехнический этап</u>: создать слой почвы со свойствами,
                благоприятными для биологической рекультивации (рыхление
                почвенного горизонта для ускорения физико-химических и
                биохимических процессов деградации загрязнителя с использованием
                мульчирующих грунтов (на сильнозагрязненных почвах); создание
                искусственного микрорельефа из чередующихся продольных
                микроповышений (бугров) и микропонижений (канавок) на
                гидроморфных почвах (с избыточным увлажнением); стимуляция
                почвенной микрофлоры при снижении концентрации загрязнителя до
                23-25% в органогенных или 15-18% в минеральных почвенных
                горизонтах в среднем по участку).
                <u>Биологический этап</u>: фитомелиорационное и агротехническое
                стимулирование почвенной микрофлоры; формирование устойчивых
                травостоев или всходов (подроста) местных древесных пород.
              </p>
            </td>
          </tr>
          <tr valign="top">
            <td>
              <p>
                <b>6-10</b>
              </p>
            </td>
            <td>
              <p>
                Контроль и недопущение самовольного и необоснованного
                замусоривания и застройки земель, пригодных для
                сельскохозяйственного производства.
              </p>
            </td>
          </tr>
          <tr valign="top">
            <td>
              <p lang="ru-RU">
                <br />
              </p>
            </td>
            <td>
              <p>
                <b>Мероприятия по борьбе с засолением</b>
              </p>
            </td>
          </tr>
          <tr valign="top">
            <td>
              <p>
                <b>7-1</b>
              </p>
            </td>
            <td>
              <p>
                Улучшение структуры почвы посредством ее обработки с
                поддержанием комковато-зернистой структуры - глубинная вспашка,
                рыхление; планировка рельефа.
              </p>
            </td>
          </tr>
          <tr valign="top">
            <td>
              <p>
                <b>7-2</b>
              </p>
            </td>
            <td>
              <p>
                Водохозяйственно-эксплуатационные мероприятия: плановое
                водопользование, рациональная организация расходов воды,
                создание водонакопителей у артезианских скважин, предотвращение
                водопотерь и фильтрации.
              </p>
            </td>
          </tr>
          <tr valign="top">
            <td>
              <p>
                <b>7-3</b>
              </p>
            </td>
            <td>
              <p>
                Мелиоративные мероприятия: промывка и гипсование при сильном
                засолении почв, посев галофитов-освоителей, рыхление плотного
                водоупорного слоя на небольшой глубине для сельскохозяйственных
                угодий.
              </p>
            </td>
          </tr>
          <tr valign="top">
            <td>
              <p>
                <b>7-4</b>
              </p>
            </td>
            <td>
              <p>
                Создание лесополос вокруг гидрообъектов с целью снижения уровня
                грунтовых вод.
              </p>
            </td>
          </tr>
          <tr valign="top">
            <td>
              <p lang="ru-RU">
                <br />
              </p>
            </td>
            <td>
              <p>
                <b>Противоэрозионные и противодефляционные мероприятия</b>
              </p>
            </td>
          </tr>
          <tr valign="top">
            <td>
              <p>
                <b>8-1</b>
              </p>
            </td>
            <td>
              <p>
                Организационно-хозяйственные мероприятия состоят в разработке
                районного почвенно-противоэрозионного плана: противоэрозионная
                организация территории, размещение дорог, окультуривание
                территории населенных пунктов, контроль за соблюдением норм
                регулирования пастьбы скота.
              </p>
            </td>
          </tr>
          <tr valign="top">
            <td>
              <p>
                <b>8-2</b>
              </p>
            </td>
            <td>
              <p>
                Регулирование поверхностного стока воды путем распашки земель
                поперек направления стока.
              </p>
            </td>
          </tr>
          <tr valign="top">
            <td>
              <p>
                <b>8-3</b>
              </p>
            </td>
            <td>
              <p>
                Механическое закрепление поверхности дефлируемых земель
                фашинами, устилочными матами различной конструкции и
                заборами-пескоуловителями.&nbsp;
              </p>
            </td>
          </tr>
          <tr valign="top">
            <td>
              <p>
                <b>8-4</b>
              </p>
            </td>
            <td>
              <p>
                Повышение прочности поверхности почвы к дефляции путем
                увеличения сил сцепления между частицам, достигаемое увеличением
                запасов влаги (особенно на почвах с тяжелым механическим
                составом), созданием комковатой структуры безотвальной
                обработкой почвы специальными почвообрабатывающими орудиями и
                применением химических структурообразующих средств; для
                улучшения почвообразовательных процессов на перепланированных
                участках рекомендуется применение структурообразователей
                (торфяной клей, смоляной клей, лигносульфонаты, крилиумы и др.)
                и подкисляющих удобрений, хорошо сочетающихся с фитомелиорацией.
              </p>
            </td>
          </tr>
          <tr valign="top">
            <td>
              <p>
                <b>8-5</b>
              </p>
            </td>
            <td>
              <p>
                Гидроинженерные противоэрозионные мероприятия предполагают
                создание искусственных накопителей (пруды, запруды и др.),
                преград (дамбы, валы), водонаправителей (валы, распылители
                стока, канавы) для аккумуляции и рассеивания водных потоков.
              </p>
            </td>
          </tr>
          <tr valign="top">
            <td>
              <p>
                <b>8-6</b>
              </p>
            </td>
            <td>
              <p>
                Лесомелиоративные, подразумевающие создание ветрорегулирующих
                лесополос на сельскохозяйственных угодьях и участках земляных
                работ с почвами легкого механического состава.
              </p>
            </td>
          </tr>
          <tr valign="top">
            <td>
              <p lang="ru-RU">
                <br />
              </p>
            </td>
            <td>
              <p>
                <b>
                  Мероприятия по предупреждению процессов подтопления и
                  заболачивания
                </b>
              </p>
            </td>
          </tr>
          <tr valign="top">
            <td>
              <p>
                <b>9-1</b>
              </p>
            </td>
            <td>
              <p>
                Защита земель сельскохозяйственного назначения от подтопления и
                заболачивания путем регулирования гидромелиоративных условий,
                реконструкции оросительной и дренажно-коллекторной сети,
                тщательной планировки полей, введения почвозащитных и
                водосберегающих технологий полива.
              </p>
            </td>
          </tr>
          <tr valign="top">
            <td>
              <p>
                <b>9-2</b>
              </p>
            </td>
            <td>
              <p>Восстановление и строительство обваловок скважин.</p>
            </td>
          </tr>
          <tr valign="top">
            <td>
              <p>
                <b>9-3</b>
              </p>
            </td>
            <td>
              <p>
                Биологический дренаж путем посадки древесно-кустарниковых и
                травянистых влаголюбивых видов.
              </p>
            </td>
          </tr>
          <tr valign="top">
            <td>
              <p>
                <b>9-4</b>
              </p>
            </td>
            <td>
              <p>
                Водопонижение в пос. Жамбыл и части пос. Мерке Меркенского
                района; в южной части а.Кулан района Т.Рыскулова; в южной части
                а. Жалпаксаз района Т.Рыскулова.
              </p>
            </td>
          </tr>
          <tr valign="top">
            <td>
              <p lang="ru-RU">
                <br />
              </p>
            </td>
            <td>
              <p>
                <b>
                  Мероприятия по предупреждению негативных последствий и
                  предотвращению стихийных явлений
                </b>
              </p>
            </td>
          </tr>
          <tr valign="top">
            <td>
              <p>
                <b>10-1</b>
              </p>
            </td>
            <td>
              <p>
                Мероприятия по борьбе с оползневыми явлениями: регулирование
                поверхности стока; планировка поверхности оползня и прилегающей
                к нему территории; устройство системы поверхностных водоотводов;
                проведение лесомелиоративных работ; дренаж обводненных пород;
                строительство подпорных и анкерных сооружений.&nbsp;
              </p>
            </td>
          </tr>
          <tr valign="top">
            <td>
              <p>
                <b>10-2</b>
              </p>
            </td>
            <td>
              <p>
                Мероприятия по борьбе с обвалами: профилактические работы и
                работы по строительству специальных противообвальных сооружений,
                осуществляемых Службами по чрезвычайным ситуациям областного и
                республиканского уровня.
              </p>
            </td>
          </tr>
          <tr valign="top">
            <td>
              <p>
                <b>10-3</b>
              </p>
            </td>
            <td>
              <p>
                Разработка планов действий в случае катастроф природного и
                антропогенного характера, включая последствия изменения климата,
                резкого ухудшения состояния, загрязнения окружающей среды и др.
              </p>
            </td>
          </tr>
          <tr valign="top">
            <td>
              <p lang="ru-RU">
                <br />
              </p>
            </td>
            <td>
              <p>
                <b>
                  Общие мероприятия для целей устойчивого развития сельских
                  территорий
                </b>
              </p>
            </td>
          </tr>
          <tr valign="top">
            <td>
              <p>
                <b>11-1</b>
              </p>
            </td>
            <td>
              <p>
                Разработка и осуществление комплексного плана управления
                природно-хозяйственной системой области для освоения и
                устойчивого использования природных ресурсов с определением
                подверженных деградационным процессам критических зон и
                приоритетов развития.
              </p>
            </td>
          </tr>
          <tr valign="top">
            <td>
              <p>
                <b>11-2</b>
              </p>
            </td>
            <td>
              <p>
                Разработка стратегии комплексного ландшафтно-проектировочного и
                ландшафтно-эстетического развития сельских населенных пунктов;
                благоустройство населенных пунктов (упорядочение и организация
                локальных водопроводов и канализации, увеличение площади зеленых
                насаждений, ликвидация мест концентрации твердых и жидких
                отходов, ремонт и прокладка дорог).
              </p>
            </td>
          </tr>
          <tr valign="top">
            <td>
              <p>
                <b>11-3</b>
              </p>
            </td>
            <td>
              <p>
                Благоустройство, упорядочение и организация зон отдыха вблизи
                населенных пунктов, включая специальный персонал и контроль за
                действиями организаций и лиц, проектирующих и обслуживающих их.
              </p>
            </td>
          </tr>
          <tr valign="top">
            <td>
              <p>
                <b>11-4</b>
              </p>
            </td>
            <td>
              <p>
                Улучшение, упорядочение и строительство подъездных путей и
                дорожной сети рекомендуется осуществлять во всех ПТК с
                выраженной неупорядоченностью и многоколейностью дорог;
                требуется реконструкция внутрихозяйственных и межхозяйственных
                дорог с твердым покрытием и осуществление рекультивации земель.
              </p>
            </td>
          </tr>
          <tr valign="top">
            <td>
              <p>
                <b>11-5</b>
              </p>
            </td>
            <td>
              <p>
                Создание искусственных водных объектов заполнением крупных
                понижений рельефа водой с целью создания водонакопителей и
                рыбопитомников, полива искусственных насаждений для улучшения
                микроклиматических условий.
              </p>
            </td>
          </tr>
          <tr valign="top">
            <td>
              <p>
                <b>11-6</b>
              </p>
            </td>
            <td>
              <p>
                Рекультивация и смягчение последствий негативных
                геолого-геоморфологических явлений: оседание поверхности земли
                (1,5-2,0 м) в окрестностях г. Тараз, Каратау, Жанатас.
              </p>
            </td>
          </tr>
          <tr valign="top">
            <td>
              <p>
                <b>11-7</b>
              </p>
            </td>
            <td>
              <p>
                Регламентирование и контроль за беспорядочной добычей
                строительных материалов.
              </p>
            </td>
          </tr>
          <tr valign="top">
            <td>
              <p>
                <b>11-8</b>
              </p>
            </td>
            <td>
              <p>
                Естественное самовосстановление нарушенных ландшафтов,
                подразумевающее разновременное невмешательство в естественный
                эволюционный ход развития природных комплексов.
              </p>
            </td>
          </tr>
          <tr valign="top">
            <td>
              <p>
                <b>11-9</b>
              </p>
            </td>
            <td>
              <p>
                Развитие просвещения и информированности общественности; широкое
                привлечение и участие населения в разработке и осуществлении
                мероприятий по устойчивому развитию окружающей среды населенных
                пунктов.
              </p>
            </td>
          </tr>
          <tr valign="top">
            <td>
              <p lang="ru-RU">
                <br />
              </p>
            </td>
            <td>
              <p>
                <b>
                  Развитие и создание сети особо охраняемых территорий (ООПТ).
                </b>
              </p>
            </td>
          </tr>
          <tr valign="top">
            <td>
              <p>
                <b>12-1</b>
              </p>
            </td>
            <td>
              <p>Инвентаризация состояния природных комплексов ООПТ.</p>
            </td>
          </tr>
          <tr valign="top">
            <td>
              <p>
                <b>12-2</b>
              </p>
            </td>
            <td>
              <p>
                Инвентаризация состояния природных объектов, которые можно
                отнести к заповедному фонду; обоснование и разработка схемы
                развития и размещения ООПТ по сохранению отдельных интересных и
                уникальных природных объектов, ландшафтов и их компонентов путем
                создания заказников, резерватов, заповедников в соответствии с
                существующим законодательством по охране окружающей среды;
                популяризация сведений об ООПТ.
              </p>
            </td>
          </tr>
          <tr valign="top">
            <td>
              <p>
                <b>12-3</b>
              </p>
            </td>
            <td>
              <p>
                Рекомендуется создание коллекционных участков и резерватов
                генофонда в ботанических садах и других научных учреждениях
                населенных пунктов.
              </p>
            </td>
          </tr>
          <tr valign="top">
            <td>
              <p>
                <b>12- 4</b>
              </p>
            </td>
            <td>
              <p>
                Нормативно-правовое подтверждение статуса создаваемых ООПТ в
                соответствии с Законом «Об особо охраняемых территориях РК».
              </p>
            </td>
          </tr>
          <tr valign="top">
            <td>
              <p lang="ru-RU">
                <br />
              </p>
            </td>
            <td>
              <p>
                <b>
                  Организация ландшафтно-экологического мониторинга и проведение
                  изыскательских и проектных работ
                </b>
              </p>
            </td>
          </tr>
          <tr valign="top">
            <td>
              <p>
                <b>13-1</b>
              </p>
            </td>
            <td>
              <p>
                Организовать наблюдения на мониторинговых площадках и
                периодически (минимум - раз в 7 лет) проводить их обследование
                для оценки сохранности и стабильности функционирования ПТК.
              </p>
            </td>
          </tr>
          <tr valign="top">
            <td>
              <p>
                <b>13-2</b>
              </p>
            </td>
            <td>
              <p>
                Проведение экспедиционных обследований за состоянием
                атмосферного воздуха четыре раза в год по годовым фазам в
                городах Каратау, Жанатас и Шу.
              </p>
            </td>
          </tr>
          <tr valign="top">
            <td>
              <p>
                <b>13-5</b>
              </p>
            </td>
            <td>
              <p>
                Осуществлять лабораторный контроль существующих и возможных
                поступлений загрязнителей в почвы и подземные воды с
                установлением экологически напряженных ареалов.
              </p>
            </td>
          </tr>
          <tr valign="top">
            <td>
              <p>
                <b>13-6</b>
              </p>
            </td>
            <td>
              <p>
                Создать глобальный каталог, базу данных и инвентаризационную
                картографическую документацию, содержащие информацию об
                источниках, видах, масштабах и последствиях проникновения в
                окружающую среду загрязнителей из разных типов источников.
              </p>
            </td>
          </tr>
          <tr valign="top">
            <td>
              <p>
                <b>13-7</b>
              </p>
            </td>
            <td>
              <p>
                Провести экологическое районирование для целей рационального
                использования природных ресурсов и охраны окружающей
                среды.&nbsp;
              </p>
            </td>
          </tr>
          <tr valign="top">
            <td>
              <p>
                <b>13-8</b>
              </p>
            </td>
            <td>
              <p>
                Разработать территориальные комплексные схемы и программы
                охраны, рационального использования и воспроизводства природных
                ресурсов на уровне административных районов.
              </p>
            </td>
          </tr>
          <tr valign="top">
            <td>
              <p>
                <b>13-9</b>
              </p>
            </td>
            <td>
              <p>
                Создать медицинскую группу по обследованию населения и
                организовать функционирование диагностического центра, оснастить
                его медоборудованием, определить уровень влияния нефтяных
                загрязнений на заболеваемость населения, осуществить
                профилактическое лечение.
              </p>
            </td>
          </tr>
        </table>
        <p lang="ru-RU">
          <br />
        </p>
        <p>
          <b>
            Перечень рекомендательных природоохранных мероприятий по
            предотвращению развития процессов опустынивания в
            природно-сельскохозяйственных системах для сельских территорий
            Туркестанской области
          </b>
        </p>
        <table cellPadding="1" cellSpacing="0">
          <col width="79" />
          <col />
          <tr valign="top">
            <td width="79" height="27">
              <p>Номерация &nbsp;</p>
            </td>
            <td>
              <p>
                Направление деятельности, виды&nbsp; и содержание мероприятий
              </p>
            </td>
          </tr>
          <tr>
            <td colSpan="2" width="657" valign="top">
              <p>
                <b>Охрана и рациональное использование пастбищ и сенокосов</b>
              </p>
            </td>
          </tr>
          <tr>
            <td width="79">
              <p>
                <b>1-1</b>
              </p>
            </td>
            <td valign="top">
              <p>
                Регулирование выпаса с учетом типа пастбищеоборота, нормирования
                нагрузок, сезонности использования пастбищ и их продуктивности.
                Рекомендуется для всех районов стабильного и удовлетворительного
                ландшафтно-экологического состояния.&nbsp;
              </p>
            </td>
          </tr>
          <tr>
            <td width="79">
              <p>
                <b>1-2</b>
              </p>
            </td>
            <td valign="top">
              <p>
                Инвентаризация общего состояния и инфраструктуры пастбищ
                (выявление объектов, пригодных для водопоя, доступов к воде,
                наличие загонов, скотопрогонов, летовок, кошар) (все
                административные районы).
              </p>
            </td>
          </tr>
          <tr>
            <td width="79">
              <p>
                <b>1-</b>

                <b>3</b>
              </p>
            </td>
            <td valign="top">
              <p>
                Запрет выпаса и фитомелиорация с одновременным подсевом
                пастбищных видов растений в соответствии с природной зоной.
                Рекомендуется на участках сильно деградированных и приаульных
                пастбищ. После восстановления растительных сообществ дальнейшая
                эксплуатация возможна с учетом пастбищеоборота и соблюдения норм
                нагрузки на пастбища (все районы с приоритетностью в
                Тюлькубасском, Сузакском, Отрарском, Шардаринском, Арысском,
                Сарыагашском и Казыгуртском административных районах).
              </p>
            </td>
          </tr>
          <tr>
            <td width="79">
              <p>
                <b>1-</b>

                <b>4</b>
              </p>
            </td>
            <td valign="top">
              <p>
                Обводнение пастбищ (реконструкция старых и обустройство новых
                колодцев, скважин) и создание сеяных пастбищ. При отсутствии
                естественных водопоев рекомендовано обеспечение подвоза воды к
                местам выпаса скота (фрагментарно во всех районах).&nbsp;
              </p>
            </td>
          </tr>
          <tr>
            <td width="79">
              <p>
                <b>1-</b>

                <b>5</b>
              </p>
            </td>
            <td valign="top">
              <p>
                Регулирование сроков и норм косьбы рекомендуется для всех видов
                сенокосов (все административные районы).
              </p>
            </td>
          </tr>
          <tr>
            <td width="79">
              <p>
                <b>1-</b>

                <b>6</b>
              </p>
            </td>
            <td valign="top">
              <p>
                Выборочное сенокошение рекомендовано в природных комплексах,
                характеризующихся удовлетворительным ландшафтно-экологическим
                состоянием и подразумевает учет сезона и агроклиматических
                условия текущего года (все административные районы).
              </p>
            </td>
          </tr>
          <tr>
            <td width="79">
              <p>
                <b>1-</b>

                <b>7</b>
              </p>
            </td>
            <td valign="top">
              <p>
                Запрет сенокошения в природных комплексах, подвергшихся
                деградации рекомендуется осуществлять на срок от 3 до 5 лет
                (Сузакский, Отрарский, Шардаринский, Сайрамский и Ордабасынский
                административные районы).&nbsp;
              </p>
            </td>
          </tr>
          <tr>
            <td colSpan="2" width="657" valign="top">
              <p>
                <b>Мероприятия по улучшению состояния пашни</b>
              </p>
            </td>
          </tr>
          <tr>
            <td width="79">
              <p>
                <b>2-1</b>
              </p>
            </td>
            <td valign="top">
              <p>
                Инвентаризация пахотно-пригодных земель и вывод из севооборота
                нерентабельных угодий (все административные районы).
              </p>
            </td>
          </tr>
          <tr>
            <td width="79">
              <p>
                <b>2-2</b>
              </p>
            </td>
            <td valign="top">
              <p>
                Введение почвозащитных и водосберегающих технологий обработки
                богарной пашни с полосным размещением культур и плоскорезной
                технологией обработки почвы, дифференцированное применение
                органических и минеральных удобрений с учетом степени деградации
                пахотных земель (все административные районы).
              </p>
            </td>
          </tr>
          <tr>
            <td width="79">
              <p>
                <b>2-3</b>
              </p>
            </td>
            <td valign="top">
              <p>
                Рекультивация деградированной пашни с применением
                агропроизводственных и агромелиоративных мероприятий (глубокая
                вспашка, севооборот многолетних трав) (Сарыагашский,
                Казыгуртский, Толебийский, Ордабасынский, им. Байдибека
                административные районы).
              </p>
            </td>
          </tr>
          <tr>
            <td width="79">
              <p>
                <b>2-4</b>
              </p>
            </td>
            <td valign="top">
              <p>
                Инвентаризация орошаемых земель и вывод из севооборота
                нерентабельных угодий (все районы).
              </p>
            </td>
          </tr>
          <tr>
            <td width="79">
              <p>
                <b>2-5</b>
              </p>
            </td>
            <td valign="top">
              <p>
                Реконструкция оросительных сетей (во всех административных
                районах).
              </p>
            </td>
          </tr>
          <tr>
            <td width="79">
              <p>
                <b>2-6</b>
              </p>
            </td>
            <td valign="top">
              <p>
                Организационно-хозяйственные мероприятия и рекультивация
                рекомендуются для длительно используемых пахотных земель
                (перепланировка, выравнивание поверхности поля, внесение
                органических и минеральных удобрений) (Мактааральский,
                Шардаринский, Сузакский, Ордабасынский, Сайрамский, им.
                Байдибека, Толебийский, Казыгуртский, Сарыагашский
                административные районы).
              </p>
            </td>
          </tr>
          <tr>
            <td colSpan="2" width="657">
              <p>
                <b>
                  Охрана лесов и растительности; фитомелиоративные мероприятия
                </b>
              </p>
            </td>
          </tr>
          <tr>
            <td width="79">
              <p>
                <b>3-1</b>
              </p>
            </td>
            <td valign="top">
              <p>
                Инвентаризация и зонирование земель лесного фонда с
                установлением назначения и режима использования и выделением
                территорий нуждающихся в охране (все административные районы,
                кроме Сайрамского, Мактааральского).
              </p>
            </td>
          </tr>
          <tr>
            <td width="79">
              <p>
                <b>3-2</b>
              </p>
            </td>
            <td valign="top">
              <p>
                Ограничение и запрещение вырубки древесно-кустарниковой
                растительности и проведение санитарной чистки в долинных
                природных комплексах в Арысском, Сузакском, Шардаринском,
                Отрарском административных районах.
              </p>
            </td>
          </tr>
          <tr>
            <td width="79">
              <p>
                <b>3-3</b>
              </p>
            </td>
            <td valign="top">
              <p>
                Ограничение и запрещение вырубки древесно-кустарниковой
                растительности в песках&nbsp; (Сузакский, Арысский,
                Шардаринский, Отрарский Сарыагашский административные
                районы).&nbsp;
              </p>
            </td>
          </tr>
          <tr>
            <td width="79">
              <p>
                <b>3-4</b>
              </p>
            </td>
            <td valign="top">
              <p>
                Запретить вырубку кустарников в горных массивах (Казыгуртский,
                Толебийский, Тюлькубасский, Сузакский, им Байдибека
                административные районы).
              </p>
            </td>
          </tr>
          <tr>
            <td width="79">
              <p>
                <b>3-5</b>
              </p>
            </td>
            <td valign="top">
              <p>
                Продолжить восстановление саксаульных плантаций (Сузакский,
                Арысский, Отрарский административные районы).
              </p>
            </td>
          </tr>
          <tr>
            <td width="79">
              <p>
                <b>3-6</b>
              </p>
            </td>
            <td valign="top">
              <p>
                Создание санитарной зеленой зоны и защитных лесополос из местных
                древесно-кустарниковых видов вокруг сельских населенных пунктов,
                полей, промышленных комплексов, водоемов, грунтовых и шоссейных
                дорог во всех административных районах.
              </p>
            </td>
          </tr>
          <tr>
            <td width="79">
              <p>
                <b>3-7</b>
              </p>
            </td>
            <td valign="top">
              <p>
                Мероприятия по озеленению территорий сельских населенных пунктов
                во всех районах.
              </p>
            </td>
          </tr>
          <tr>
            <td colSpan="2" width="657" valign="bottom">
              <p>
                <b>
                  Мероприятия по охране и рациональному использованию водных
                  ресурсов
                </b>
              </p>
            </td>
          </tr>
          <tr>
            <td width="79">
              <p>
                <b>4-1</b>
              </p>
            </td>
            <td valign="top">
              <p>
                Инвентаризация, качественная и техническая оценка состояния
                подземных и поверхностных водных источников (реки, озера,
                водохранилища), централизованных и децентрализованных систем
                питьевого водоснабжения (все административные районы
                области).&nbsp;
              </p>
            </td>
          </tr>
          <tr>
            <td width="79">
              <p>
                <b>4-2</b>
              </p>
            </td>
            <td valign="top">
              <p>
                Разработка превентивных мер по снижению уровня загрязнения воды
                в р.Сырдарья, Келес, Бадам (Отрарский, Шардаринский, Арысский,
                Мактааральский, им. Байдибека, Ордабасынский, Сайрамский,
                Сарыагашский, Казыгуртский административные районы) и
                межрегиональных обводнительно-оросительных систем
                (Мактааральский, Шардаринский, Сарыагашский, Казыгуртский,
                Сайрамский, Ордабасынский районы и территория, подчиненная
                маслихату г. Туркистана.
              </p>
            </td>
          </tr>
          <tr>
            <td width="79">
              <p>
                <b>4-3</b>
              </p>
            </td>
            <td valign="top">
              <p>
                Контроль за качеством питьевого водоснабжения, и улучшение
                состояния питьевого водоснабжения за счет строительства
                водопроводов, модернизации технологических процессов
                обеззараживания и водоочистки, внедрения водосберегающего режима
                использования питьевых вод, контроля за расходованием воды во
                всех населенных пунктах области, и в первую очередь в районах,
                характеризующихся наиболее высокими показателями загрязнения
                (Арысский, Шардаринский, им. Байдибека, Казыгуртский,
                Толебийский).
              </p>
            </td>
          </tr>
          <tr>
            <td width="79">
              <p>
                <b>4-4</b>
              </p>
            </td>
            <td valign="top">
              <p>
                Реконструкция и строительство очистных сооружений,
                канализационных сетей, водоводов и насосных станций,
                реабилитация ранее построенных локальных водопроводов в крупных
                поселках и повышение эффективности их работы. Реконструкция
                децентрализованных систем водоснабжения (колодцы, скважины) и
                строительство новых в небольших по численности населенных
                пунктах (для всех районов). В первую очередь осуществить
                строительство и реконструкцию водопроводных сооружений в
                Казыгуртском, Шардаринском, Отрарском, Арысском, Сузакском, им.
                Байдибека, Сарыагашском административных районах.&nbsp;
              </p>
            </td>
          </tr>
          <tr>
            <td width="79">
              <p>
                <b>4-5</b>
              </p>
            </td>
            <td valign="top">
              <p>
                Осуществление мероприятий и контроль за использованием подземных
                вод с повышенным уровнем минерализации и загрязнения (Абайское,
                Бадам-Сайрамскоое, Тассай-Аксуское месторождения) (Сарыагашский,
                Сайрамский, Ордабасынский административные районы).&nbsp;
              </p>
            </td>
          </tr>
          <tr>
            <td width="79">
              <p>
                <b>4-6</b>
              </p>
            </td>
            <td valign="top">
              <p>
                Исключение из употребления воды для питьевых целей по причине их
                загрязнения из шести скважин в Сузакском, двух в Шардаринском
                административных районах и двух скважин на территории,
                подчиненной маслихату г. Туркистана.&nbsp;
              </p>
            </td>
          </tr>
          <tr>
            <td width="79">
              <p>
                <b>4-7</b>
              </p>
            </td>
            <td valign="top">
              <p>
                Строительство очистных сооружений и накопителей на территории
                промышленных и сельскохозяйственных объектов и контроль за
                предотвращением аварийных и сточных сбросов в водные объекты и
                понижения рельефа (Арысский, Ордабасынский, Сайрамский,
                Мактааральский районы, территория, подчиненная маслихату г.
                Туркистана и Шымкента).
              </p>
            </td>
          </tr>
          <tr>
            <td width="79">
              <p>
                <b>4-8</b>
              </p>
            </td>
            <td valign="top">
              <p>
                Создание и обустройство водоохранных и санитарных зон вокруг рек
                и водохранилищ, контроль за их состоянием, выполнение
                водоохранных мероприятий, осуществление лесонасаждений
                (Отрарский, Толебийский, Шардаринский, Арысский, Мактааральский,
                Ордабасынский, им. Байдибека Сайрамский, Сарыагашский,
                Казыгуртский административные районы).&nbsp;
              </p>
            </td>
          </tr>
          <tr>
            <td width="79">
              <p>
                <b>4-9</b>
              </p>
            </td>
            <td valign="top">
              <p>
                Разработка мероприятий по предотвращению загрязнения,
                обмеления&nbsp; и засоления озера Кзылколь (Сузакский район) и
                Шошкакольской озерной системы&nbsp; (Отрарский район),
                являющихся местом гнездования и местом отдыха перелетных птиц.
              </p>
            </td>
          </tr>
          <tr>
            <td width="79">
              <p>
                <b>4-10</b>
              </p>
            </td>
            <td valign="top">
              <p>
                Разработка комплексных подходов к водозабору, водораспределению,
                очистке, использованию и сбросу вод во всех административных
                районах области.
              </p>
            </td>
          </tr>
          <tr>
            <td width="79">
              <p>
                <b>4- 11</b>
              </p>
            </td>
            <td valign="top">
              <p>
                Реконструкция существующих дамб и гидротехнических сооружений,
                укрепление берегов на водохранилищах (Толебийский,
                Мактааральский, Сараагашский и Шардаринский, им. Байдибека,
                Арысский, Ордабасынский районы). Установление водоохранных
                знаков на реках и гидроузлах.&nbsp;
              </p>
            </td>
          </tr>
          <tr>
            <td colSpan="2" width="657">
              <p>
                <b>
                  Мероприятия по предупреждению загрязнения приземного
                  атмосферного воздуха
                </b>
              </p>
            </td>
          </tr>
          <tr>
            <td width="79">
              <p>
                <b>5-1</b>
              </p>
            </td>
            <td valign="top">
              <p>
                Инвентаризация, мониторинг и обновление данных об организованных
                и неорганизованных источниках-загрязнителях атмосферного воздуха
                во всех административных районах.&nbsp;
              </p>
            </td>
          </tr>
          <tr>
            <td width="79">
              <p>
                <b>5-2</b>
              </p>
            </td>
            <td valign="top">
              <p>
                Контроль за выполнением воздухоохранных мероприятий на
                промышленных предприятиях области (рекультивация нарушенных
                земель в районах недропользования, оборудование полигонов для
                складирования бытовых и промышленных отходов) (Сузакский,
                Сарыагашский,&nbsp; Толебийский, Шардаринский административные
                районы, территории подчиненные маслихатам г. Туркистана,
                Шымкента).
              </p>
            </td>
          </tr>
          <tr>
            <td width="79">
              <p>
                <b>5-3</b>
              </p>
            </td>
            <td valign="top">
              <p>
                Введение запрета на использование земель в сельскохозяйственном
                обороте вблизи от источников выбросов промышленных предприятий и
                добывающих комплексов (Толебийский, Арысский, Тюлькубасский,
                Казыгуртский, Сузакский, Сарыагашский, Отрарский
                административные районы, территории подчиненные маслихатам г.
                Туркистана и Шымкента).&nbsp;
              </p>
            </td>
          </tr>
          <tr>
            <td width="79">
              <p>
                <b>5-4</b>
              </p>
            </td>
            <td valign="top">
              <p>
                Проектирование, строительство и обустройство природоохранных и
                санитарно-защитных зон вокруг промышленных и
                сельскохозяйственных предприятий – источников загрязнения
                атмосферного воздуха (районные центры и центры городских
                администраций).
              </p>
            </td>
          </tr>
          <tr>
            <td width="79">
              <p>
                <b>5-5</b>
              </p>
            </td>
            <td valign="top">
              <p>
                Внедрение передовых технологий для минимизации воздействия
                загрязняющих веществ автотранспорта на природную среду (все
                районы).
              </p>
            </td>
          </tr>
          <tr>
            <td width="79">
              <p>
                <b>5-6</b>
              </p>
            </td>
            <td valign="top">
              <p>
                Расширение старых и создание новых защитных зон из зеленых
                насаждений вдоль автомагистралей (все районы).
              </p>
            </td>
          </tr>
          <tr>
            <td colSpan="2" width="657">
              <p>
                <b>
                  Мероприятия по предупреждению и ликвидации загрязнения
                  почвенного покрова&nbsp;
                </b>
              </p>
            </td>
          </tr>
          <tr>
            <td width="79">
              <p>
                <b>6-1</b>
              </p>
            </td>
            <td valign="top">
              <p>
                Создание систем контроля за загрязнением почв, вблизи
                сельскохозяйственных предприятий и разработка превентивных мер
                по ликвидации почвенного загрязнения (Сузакский, Ордабасынский,
                Сайрамский, Мактааральский, им. Байдибека, Отрарский
                административные районы).&nbsp;
              </p>
            </td>
          </tr>
          <tr>
            <td width="79">
              <p>
                <b>6-2</b>
              </p>
            </td>
            <td valign="top">
              <p>
                Создание систем контроля за загрязнением почв, вблизи
                промышленных предприятий, рудников и месторождений полезных
                ископаемых и разработка превентивных мер по ликвидации
                почвенного загрязнения (Толебийский, Арысский, Тюлькубасский,
                Казыгуртский, Сузакский, Сарыагашский, Отрарский
                административные районы, территории подчиненные маслихатам г.
                Туркистана,&nbsp; г. Шымкента).
              </p>
            </td>
          </tr>
          <tr>
            <td width="79">
              <p>
                <b>6-3</b>
              </p>
            </td>
            <td valign="top">
              <p>
                Инвентаризация и качественная оценка состояния орошаемых земель
                на содержание в них загрязняющих веществ (все районы).
              </p>
            </td>
          </tr>
          <tr>
            <td width="79">
              <p>
                <b>6-4</b>
              </p>
            </td>
            <td valign="top">
              <p>
                Ликвидация&nbsp; стихийных свалок (все административные&nbsp;
                районы).
              </p>
            </td>
          </tr>
          <tr>
            <td width="79">
              <p>
                <b>6-5</b>
              </p>
            </td>
            <td valign="top">
              <p>
                Обустройство существующих и строительство новых полигонов для
                хранения твердых и бытовых отходов в каждом населенном пункте
                при первоочередном решении данной проблемы в Сузакском,
                Отрарском районах, территории подчиненной маслихатам г.
                Туркистана и Шымкента).
              </p>
            </td>
          </tr>
          <tr>
            <td width="79">
              <p>
                <b>6-6</b>
              </p>
            </td>
            <td valign="top">
              <p>
                Строительство полигонов по складированию токсичных отходов
                (Сузакский, Сайрамский административные районы).
              </p>
            </td>
          </tr>
          <tr>
            <td width="79">
              <p>
                <b>6-7</b>
              </p>
            </td>
            <td valign="top">
              <p>
                Рекультивация нарушенных земель в районах недропользования
                (Толебийский, Арысский, Тюлькубасский, Казыгуртский, Сузакский,
                Сарыагашский, Отрарский административные районы).
              </p>
            </td>
          </tr>
          <tr>
            <td colSpan="2" width="657">
              <p>
                <b>Мероприятия по борьбе с засолением почв</b>
              </p>
            </td>
          </tr>
          <tr>
            <td width="79">
              <p>
                <b>7-1</b>
              </p>
            </td>
            <td valign="top">
              <p>
                Агропроизводственные и агромелиоративные мероприятия по
                улучшению физических и химических свойств почв на богарных и
                орошаемых пахотных землях (все районы с первоочередным
                проведением выше названных мероприятий в им. Байдибека,
                Толебийском, Казыгуртском, Мактааральском, Сарыагашском
                административных районах).
              </p>
            </td>
          </tr>
          <tr>
            <td width="79">
              <p>
                <b>7-2</b>
              </p>
            </td>
            <td valign="top">
              <p>
                Соблюдение норм полива, реконструкция оросительных и дренажных
                систем. Контроль за санитарно-экологическими попусками воды (все
                районы).
              </p>
            </td>
          </tr>
          <tr>
            <td width="79">
              <p>
                <b>7-3</b>
              </p>
            </td>
            <td valign="top">
              <p>
                Проведение мероприятий по снижению процессов засоления на пашни,
                выведение из севооборота сильнозасоленных земель и перевод их в
                разряд пастбищ (Ордабасынский, Сузакский, Шардаринский,
                Мактааральский, Отрарский районы, территория, подчиненная
                маслихату г. Туркистана).
              </p>
            </td>
          </tr>
          <tr>
            <td colSpan="2" width="657">
              <p>
                <b>Противоэрозионные и противодефляционные мероприятия</b>
              </p>
            </td>
          </tr>
          <tr>
            <td width="79">
              <p>
                <b>8-1</b>
              </p>
            </td>
            <td valign="top">
              <p>
                Инвентаризация и картирование дефляционно-опасных земель во всех
                районах.
              </p>
            </td>
          </tr>
          <tr>
            <td width="79">
              <p>
                <b>8-2</b>
              </p>
            </td>
            <td valign="top">
              <p>
                Лесомелиоративные и влаго-удерживающие мероприятия с созданием
                ветрорегулирующих лесополос и установление искусственных
                ограждений в зимнее время, регулирование выпаса на пастбищах с
                почвами легкого механического состава (Сузакский, Арысский,
                Шардаринский, Отрарский административные районы).
              </p>
            </td>
          </tr>
          <tr>
            <td width="79">
              <p>
                <b>8-3</b>
              </p>
            </td>
            <td valign="top">
              <p>
                Осуществление противодефляционных мероприятий на пахотных землях
                (обеспечение комковатой структуры за счет увеличения запасов
                влаги, залужение) в Сарыагашском, им. Байдибека, Казыгуртском,
                Толебийском административных районах.
              </p>
            </td>
          </tr>
          <tr>
            <td width="79">
              <p>
                <b>8-4</b>
              </p>
            </td>
            <td valign="top">
              <p>
                Инвентаризация и картирование эрозионно-опасных земель во всех
                районах.
              </p>
            </td>
          </tr>
          <tr>
            <td width="79">
              <p>
                <b>8-5</b>
              </p>
            </td>
            <td valign="top">
              <p>
                Агрономические противоэрозионные мероприятия (регулирование
                поверхностного стока воды путем вспашки и посева
                сельскохозяйственных культур поперек стока воды) (Сарыагашский,
                им. Байдибека, Ордабасынский, Казыгуртский, Отрарский,
                Толебийский, Тюлькубасский административные районы).
              </p>
            </td>
          </tr>
          <tr>
            <td width="79">
              <p>
                <b>8-6</b>
              </p>
            </td>
            <td valign="top">
              <p>
                Лесомелиоративные и фитомелиоративные противоэрозионные
                мероприятия (создание водорегулирующих лесополос в местах
                проведения земледельческих работ, залужение) (Сарыагашский, им.
                Байдибека, Казыгуртский, Толебийский районы).&nbsp;
              </p>
            </td>
          </tr>
          <tr>
            <td width="79">
              <p>
                <b>8-7</b>
              </p>
            </td>
            <td valign="top">
              <p>
                Осуществление технических гидроинженерных и противоэрозионных
                мероприятий: создание искусственных накопителей (пруды, запруды
                и др.), преград (дамбы, валы), водозадерживающих и водоотводящих
                сооружений, реконструкция существующих дамб и гидротехнических
                сооружений, укрепление берегов на водохранилищах
                (Мактааральский, Шардаринский, Сарыагашский, Казыгуртский,
                Ордабасынский, Арысский, Отрарский, им. Байдибека
                административные районы и территория, подчиненная маслихату г.
                Туркистана).&nbsp;
              </p>
            </td>
          </tr>
          <tr>
            <td colSpan="2" width="657" valign="top">
              <p>
                <b>Мероприятия по предупреждению процессов заболачивания</b>
              </p>
            </td>
          </tr>
          <tr>
            <td width="79">
              <p>
                <b>9-1</b>
              </p>
            </td>
            <td valign="top">
              <p>
                Регулирование и контроль за водоподачей и технологий полива,
                реконструкция оросительной сети, планировка полей, введение
                почвозащитных и водосберегающих технологий полива во всех
                районах.
              </p>
            </td>
          </tr>
          <tr>
            <td width="79">
              <p>
                <b>9-2</b>
              </p>
            </td>
            <td valign="top">
              <p>
                Контроль за санитарно-экологическими попусками воды,
                предотвращение водопотерь и фильтрации в районах расположения
                озер и водохранилищ, таких как Шардаринское, Бадамское,
                Бугунское, Ирмак-Узенское, Майданталское, Коскурганское,
                Капшагайское в Шардаринском, Мактааральском, Казыгуртском,
                Сарыагашском, Ордабасынском, им. Байдибека административных
                районах.&nbsp;
              </p>
            </td>
          </tr>
          <tr>
            <td width="79">
              <p>
                <b>9-3</b>
              </p>
            </td>
            <td valign="top">
              <p>
                Реализация биологического дренажа с посадкой
                древесно-кустарниковых и травянистых влаголюбивых видов в местах
                расположения запруд, водохранилищ и рек в Отрарском,
                Шардаринском, Арысском, Суакском, Мактааральском, Ордабасынском,
                Казыгуртском, Сарыагашском, им.&nbsp; Байдибека районах.
              </p>
            </td>
          </tr>
          <tr>
            <td colSpan="2" width="657">
              <p>
                <b>
                  Мероприятия по предупреждению негативных последствий и
                  предотвращению стихийных явлений
                </b>
              </p>
            </td>
          </tr>
          <tr>
            <td width="79">
              <p>
                <b>10-1</b>
              </p>
            </td>
            <td valign="top">
              <p>
                Контроль за санитарно-экологическим состоянием
                источников-загрязнителей окружающей среды (хвостохранилища,
                отвалы добывающих пород, золоотвалы, склады ТБО, радиоактивные
                захоронения и т.д.) (Толебийский, Арысский, Тюлькубасский,
                Казыгуртский, Сузакский, Сарыагашский, Ордабасынский, Отрарский
                административные районы, территории подчиненные маслихатам г.
                Туркистана и Шымкента).
              </p>
            </td>
          </tr>
          <tr>
            <td width="79">
              <p>
                <b>10-2</b>
              </p>
            </td>
            <td valign="top">
              <p>
                Мониторинг окружающей среды территорий, подверженных воздействию
                последствий испытания на ядерных полигонах в Сузакском и
                Отрарском районах.
              </p>
            </td>
          </tr>
          <tr>
            <td width="79">
              <p>
                <b>10-3</b>
              </p>
            </td>
            <td valign="top">
              <p>
                Реализация и контроль природоохранных мероприятий по борьбе с
                негативными природными явлениями (аварийные сбросы водохранилищ,
                паводковые явления, пыльные бури, пожары, и т.д.) во всех
                административных районах.
              </p>
            </td>
          </tr>
          <tr>
            <td colSpan="2" width="657">
              <p>
                <b>
                  Общие мероприятия для целей устойчивого развития сельских
                  территорий
                </b>
              </p>
            </td>
          </tr>
          <tr>
            <td width="79">
              <p>
                <b>11-1</b>
              </p>
            </td>
            <td valign="top">
              <p>
                Разработка и реализация комплексного плана управления и
                устойчивого развития сельских районов с учетом
                ландшафтно-экологических требований к охране земельных ресурсов
                и требований к повышению уровня жизнеспособности и
                жизнеобеспеченности населения.
              </p>
            </td>
          </tr>
          <tr>
            <td width="79">
              <p>
                <b>11-2</b>
              </p>
            </td>
            <td valign="top">
              <p>
                Разработка и реализация стратегии комплексного
                ландшафтно-проектировочного и ландшафтно-эстетического развития
                сельских населенных пунктов.
              </p>
            </td>
          </tr>
          <tr>
            <td width="79">
              <p>
                <b>11-3</b>
              </p>
            </td>
            <td valign="top">
              <p>
                Экологическое образование и воспитание сельского населения
                (участие сельского населения в разработке и реализации
                мероприятий по охране и устойчивому развитию окружающей среды
                городов, поселков и сельских населенных пунктов).
              </p>
            </td>
          </tr>
          <tr>
            <td width="79">
              <p>
                <b>11-4</b>
              </p>
            </td>
            <td valign="top">
              <p>
                Благоустройство населенных пунктов (упорядочение и организация
                локальных водопроводов и канализации, увеличение площади зеленых
                насаждений, ликвидация мест концентрации твердых и жидких
                отходов, ремонт и строительство дорог, организация и
                благоустройство зон отдыха).
              </p>
            </td>
          </tr>
          <tr>
            <td width="79">
              <p>
                <b>11-5</b>
              </p>
            </td>
            <td valign="top">
              <p>
                Регламентация и обустройство дорожной сети сельских территорий.
              </p>
            </td>
          </tr>
          <tr>
            <td width="79">
              <p>
                <b>11-6</b>
              </p>
            </td>
            <td valign="top">
              <p>
                Регламентирование и контроль за беспорядочной добычей
                строительных материалов и полезных ископаемых.
              </p>
            </td>
          </tr>
          <tr>
            <td width="79">
              <p>
                <b>11-7</b>
              </p>
            </td>
            <td valign="top">
              <p>
                Организация мониторинговых наблюдения за состоянием природных
                комплексов.
              </p>
            </td>
          </tr>
          <tr>
            <td width="79">
              <p>
                <b>11-8</b>
              </p>
              <p lang="ru-RU">
                <br />
              </p>
            </td>
            <td valign="top">
              <p>
                Мониторинг социально-демографического состояния сельского
                населения.
              </p>
            </td>
          </tr>
          <tr>
            <td colSpan="2" width="657">
              <p>
                <b>
                  Развитие и создание сети особо охраняемых территорий (ООПТ).
                </b>
              </p>
            </td>
          </tr>
          <tr>
            <td width="79">
              <p>
                <b>12-1</b>
              </p>
            </td>
            <td valign="top">
              <p>
                Инвентаризация состояния существующих ООПТ (Аксу-Жабаглинский,
                Каратауский заповедники, Жамбылский, Урский, Задарьинский,
                Акдалинский, Боралдайский, Ирсу-Даубабинский, Сайрам-Угамский
                заказники), а так же объектов, которые можно отнести к
                природно-заповедному фонду и популяризация сведений об ООПТ (все
                административные районы).&nbsp;
              </p>
            </td>
          </tr>
          <tr>
            <td width="79">
              <p>
                <b>12-2</b>
              </p>
            </td>
            <td valign="top">
              <p>
                Придать статус природного парка территории озера Кзылколь
                (Сузакский район) и территории, прилегающей к&nbsp;
                Шошкакольской озерной системе&nbsp; (Отрарский район),
                являющихся местом гнездования и отдыха перелетных птиц.&nbsp;
              </p>
            </td>
          </tr>
        </table>
        <p lang="ru-RU">
          <br />
        </p>
        <p>
          <b>
            Перечень рекомендательных природоохранных мероприятий по
            предотвращению развития процессов опустынивания в
            природно-сельскохозяйственных системах для сельских территорий
            Алматинской области и для сельских территорий Жетысуской области
          </b>
        </p>
        <table cellPadding="1" cellSpacing="0">
          <col width="65" />
          <col />
          <tr valign="top">
            <td width="65" height="27">
              <p>Номерация &nbsp;</p>
            </td>
            <td>
              <p>
                Направление деятельности, виды&nbsp; и содержание мероприятий
              </p>
            </td>
          </tr>
          <tr>
            <td colSpan="2" width="643" height="11">
              <p>
                <b>Охрана и рациональное использование пастбищ и сенокосов</b>
              </p>
            </td>
          </tr>
          <tr>
            <td width="65" height="28">
              <p>
                <b>1-1</b>
              </p>
            </td>
            <td>
              <p>
                Инвентаризация орошаемых земель; вывод из севооборота
                нерентабельных земель; проведение организационно-хозяйственных
                мероприятий; рекультивация длительно-орошаемых пахотных земель с
                применением агропроизводственных и агромелиоративных мероприятий
                (безотвальная обработка, глубокая вспашка, севооборот
                многолетних трав) в Жамбылском и Талгарском районах области.
              </p>
            </td>
          </tr>
          <tr>
            <td width="65" height="44">
              <p>
                <b>1-2</b>
              </p>
            </td>
            <td>
              <p>
                Прекратить распашку земель и использование ядохимикатов в
                пределах прибрежных водоохранных зон и водозаборов на территории
                районов
              </p>
            </td>
          </tr>
          <tr>
            <td width="65" height="53">
              <p>
                <b>1-3&nbsp;</b>
              </p>
            </td>
            <td>
              <p>
                Совершенствование структуры посевных площадей, оптимизация
                соотношения различных культур в соответствии с
                почвенно-климатическими особенностями зон и требованиями рынка в
                Илейском, Енбекшиказахском, Жамбылском, Карасайском, Талгарском
                и Райымбекском районах.
              </p>
            </td>
          </tr>
          <tr>
            <td width="65" height="70">
              <p>
                <b>1-4&nbsp;</b>
              </p>
            </td>
            <td>
              <p>
                В районах областей осуществлять регулирование выпаса скота с
                учетом типов пастбищ, пастбищных нагрузок. Запретить выпас и
                произвести фитомелиорацию с одновременным подсевом пастбищных
                растений на сильно деградированных угодьях, в том числе,
                присельских пастбищах населенных пунктов в Панфиловском,
                Кербулакском, особенно в Алакольском районах.
              </p>
            </td>
          </tr>
          <tr>
            <td width="65" height="52">
              <p>
                <b>1-5&nbsp;</b>
              </p>
            </td>
            <td>
              <p>
                Регулирование выпаса с учетом типа пастбищеоборота, нормирования
                нагрузок, сезонности использования пастбищ и их продуктивности.
                Рекомендуется для всех районов области стабильного и
                удовлетворительного ландшафтно-экологического состояния.
              </p>
            </td>
          </tr>
          <tr>
            <td width="65" height="74">
              <p>
                <b>1-6&nbsp;</b>
              </p>
            </td>
            <td>
              <p>
                По всей территории области активизировать работу, направленную
                на водоснабжение пастбищ и расширение комплексной системы
                водоснабжения с инфраструктурой. В Уйгурском районе провести
                инвентаризацию обводнительных сооружений, строительство новых
                колодцев с использованием современных технологий и реконструкцию
                имеющихся шахтных и трубчатых колодцев, расширить сеть
                искусственных мест водопоя.
              </p>
            </td>
          </tr>
          <tr>
            <td width="65">
              <p>
                <b>1-7&nbsp;</b>
              </p>
            </td>
            <td>
              <p>
                Проведение мероприятий по диагностике, профилактике и ликвидации
                особо опасных болезней животных в Аксуском районе.
              </p>
            </td>
          </tr>
          <tr>
            <td width="65" height="35">
              <p>
                <b>1-8&nbsp;</b>
              </p>
            </td>
            <td>
              <p>
                Инвентаризация общего состояния и инфраструктуры пастбищ
                (выявление объектов, пригодных для водопоя, доступов к воде,
                наличие загонов, скотопрогонов, летовок, кошар).
              </p>
            </td>
          </tr>
          <tr>
            <td width="65" height="70">
              <p>
                <b>1-9&nbsp;</b>
              </p>
            </td>
            <td>
              <p>
                Рекомендуется содержать сельскохозяйственных животных с
                соблюдением зоогигиенических, технологических и
                ветеринарно-санитарных требований. Обеспечить скотомогильниками,
                ветеринарно-санитарными объектами населенные пункты Уйгурского,
                Панфиловского и Райымбекского районов, в диагностических
                лабораториях технологическим оборудованием и оснащением.
              </p>
            </td>
          </tr>
          <tr>
            <td width="65" height="54">
              <p>
                <b>1-10&nbsp;</b>
              </p>
            </td>
            <td>
              <p>
                Обводнение пастбищ (реконструкция старых и обустройство новых
                колодцев, скважин) и создание сеяных пастбищ в Балкашском и
                Аксуском районе. При отсутствии естественных водопоев
                рекомендовано обеспечение подвоза воды к местам выпаса скота.
              </p>
            </td>
          </tr>
          <tr>
            <td width="65" height="66">
              <p>
                <b>1-11</b>
              </p>
            </td>
            <td>
              <p>
                За счет республиканского бюджета провести почвенное
                обследование, определение бонитета почв земель
                сельскохозяйственного назначения, создание электронных
                земельно-кадастровых карт Райымбекского и Алакольского районов,
                паспортизацию крестьянских хозяйств, межевание не
                координированных земельных участков и другие виды работ.
              </p>
            </td>
          </tr>
          <tr>
            <td width="65" height="54">
              <p>
                <b>1-12&nbsp;</b>
              </p>
            </td>
            <td>
              <p>
                Проведение геоинформационного мониторинга в связи с
                неблагополучной эпидемиологической ситуацией в Илейском,
                Балкашском и Кербулакском районах. Своевременно проводить
                ветеринарно-профилактические мероприятия, обеспечивающие
                эпизоотическое благополучие пастбищ
              </p>
            </td>
          </tr>
          <tr>
            <td width="65" height="57">
              <p>
                <b>1-13&nbsp;</b>
              </p>
            </td>
            <td>
              <p>
                В животноводстве необходимо развивать средне и крупнотоварные
                производства, проводить крупномасштабные селекционно-племенные
                работы, сбалансированное кормление скота и рациональное
                использование имеющихся пастбищ; улучшить работу по
                искусственному осеменению крупного рогатого скота.
              </p>
            </td>
          </tr>
          <tr>
            <td colSpan="2" width="643" height="22">
              <p>
                <b>Мероприятия по улучшению состояния пашни</b>
              </p>
            </td>
          </tr>
          <tr>
            <td width="65" height="35">
              <p>
                <b>2-1</b>
              </p>
            </td>
            <td>
              <p>
                Увеличить площади орошаемых земель за счет эффективного и
                рационального использования земель и поливной воды на территории
                Жамбылского, Панфиловского и Аксуского районов.
              </p>
            </td>
          </tr>
          <tr>
            <td width="65" height="115">
              <p>
                <b>2-2&nbsp;</b>
              </p>
            </td>
            <td>
              <p>
                Введение почвозащитных и водосберегающих технологий обработки
                богарной пашни с полосным размещением культур и плоскорезной
                технологией обработки почвы, дифференцированное применение
                органических и минеральных удобрений с учетом степени деградации
                пахотных земель по всей территории. Агрономические мероприятия
                для пашни равнин и предгорий – поперечная вспашка земель на
                склонах, накопление влаги, улучшение вводно-физических свойств и
                окультуривание пашни (увеличение мощности пахотного слоя,
                обогащение органическими веществами, известкование и
                гипсование), плоскорезная, безотвальная и нулевая обработка
                пашни, полосное размещение пара и пропашных культур, севооборот
                и мульчирование.
              </p>
            </td>
          </tr>
          <tr>
            <td width="65" height="33">
              <p>
                <b>2-3&nbsp;</b>
              </p>
            </td>
            <td>
              <p>
                Расширить площади внедрения влагоресурсосберегающих технологий.
                Совершенствовать культуру орошения, соблюдать поливной режим.
                Внедрение капельного орошения в Саркандском и Ескельдинском
                районах.
              </p>
            </td>
          </tr>
          <tr>
            <td width="65" height="53">
              <p>
                <b>2-4&nbsp;</b>
              </p>
            </td>
            <td>
              <p>
                Расширение посевных площадей сельскохозяйственных культур,
                закладка и выращивание многолетних насаждений плодово-ягодных
                культур. Закладка и выращивание многолетних насаждений плодовых
                культур в Аксуском районе.
              </p>
            </td>
          </tr>
          <tr>
            <td width="65" height="42">
              <p>
                <b>2-5&nbsp;</b>
              </p>
            </td>
            <td>
              <p>
                Ввести в эксплуатацию пять высокопродуктивных семеноводческих
                хозяйств по производству семян сельскохозяйственных культур,
                которые будут обеспечивать 21 % потребности района на семена.
              </p>
            </td>
          </tr>
          <tr>
            <td width="65" height="52">
              <p>
                <b>2-6</b>
              </p>
            </td>
            <td>
              <p>
                Организационно-хозяйственные мероприятия и рекультивация для
                длительно используемых пахотных земель (перепланировка,
                выравнивание поверхности поля, внесение органических и
                минеральных удобрений) в Каратальском и Коксуском районах.
              </p>
            </td>
          </tr>
          <tr>
            <td width="65" height="82">
              <p>
                <b>2-7&nbsp;</b>
              </p>
            </td>
            <td>
              <p>
                Рекомендовать выращивание сельскохозяйственных культур по
                интенсивной технологии, влагосберегающей (нулевой) технологии, с
                использованием капельного орошения на пашне, соблюдать
                научно-обоснованный севооборот на территории Панфиловского,
                Уйгурского и Райымбекского районов. Особое внимание уделить на
                использование экономичных методов полива сельскохозяйственных
                культур: с использованием дождевального агрегата, закрытых труб
                и шлангов, капельного вида орошения.
              </p>
            </td>
          </tr>
          <tr>
            <td width="65" height="50">
              <p>
                <b>2-8&nbsp;</b>
              </p>
            </td>
            <td>
              <p>
                Определение оптимального соотношения площади
                сельскохозяйственных угодий и вывод из севооборота
                нерентабельных пахотных земель. Выдерживание технологий
                возделывания полевых, технических, кормовых и овощебахчевых
                культур, сокращение площадей влагоемких культур в Балкашском и
                Жамбылском районах.
              </p>
            </td>
          </tr>
          <tr>
            <td width="65" height="35">
              <p>
                <b>2-9&nbsp;</b>
              </p>
            </td>
            <td>
              <p>
                Применение влагосберегающей технологии для выращивания сельхоз
                культур - до 16400 га, применение капельного орошения - до 60 га
                в Ескельдинском районе.
              </p>
            </td>
          </tr>
          <tr>
            <td width="65" height="67">
              <p>
                <b>2-10&nbsp;</b>
              </p>
            </td>
            <td>
              <p>
                Введение почвозащитных и водосберегающих технологий полива, его
                качественное улучшение, осуществление противоэрозионной техники
                полива (полив по наименьшему уклону, контурное орошение, полив
                переменным расходом струи в борозду, применение дождевальных
                установок, реконструировать старую оросительную сеть с
                использованием полиэтиленовых труб) во всех районах области.
              </p>
            </td>
          </tr>
          <tr>
            <td width="65" height="1">
              <p>
                <b>2-11&nbsp;</b>
              </p>
            </td>
            <td>
              <p>
                В Алакольском районе произвести ремонт парка
                сельскохозяйственной техники, что позволит повысить
                производительность труда и рост производства.
              </p>
            </td>
          </tr>
          <tr>
            <td width="65" height="35">
              <p>
                <b>2-12&nbsp;</b>
              </p>
            </td>
            <td>
              <p>
                Диверсификация отрасли растениеводство путем расширения площади
                приоритетных сельскохозяйственных культур, переход на
                ресурсосберегающие технологии возделывания культур в Алакольском
                районе.
              </p>
            </td>
          </tr>
          <tr>
            <td width="65" height="45">
              <p>
                <b>2-13&nbsp;</b>
              </p>
            </td>
            <td>
              <p>
                Расширение посевных площадей и применение передовых технологий
                возделывания сельскохозяйственных культур по всей территории
                области.
              </p>
            </td>
          </tr>
          <tr>
            <td width="65" height="34">
              <p>
                <b>2-14&nbsp;</b>
              </p>
            </td>
            <td>
              <p>
                Расширение посевных площадей и применение передовых технологий
                возделывания сельскохозяйственных культур в Аксуском районе.
              </p>
            </td>
          </tr>
          <tr>
            <td width="65" height="38">
              <p>
                <b>2-15</b>
              </p>
            </td>
            <td>
              <p>
                В Жамбылском районе произвести расширение посевных площадей и
                применение передовых технологий возделывания
                сельскохозяйственных культур.
              </p>
            </td>
          </tr>
          <tr>
            <td width="65" height="86">
              <p>
                <b>2-17</b>
              </p>
            </td>
            <td>
              <p>
                Закладка и выращивание многолетних насаждений плодово-ягодных
                культур и винограда на территории Жамбылского района. Увеличение
                площадей орошаемых земель Жамбылского района, за счет
                эффективного и рационального использования земель и поливной
                воды. Проведение химической борьбы на прогнозируемой площади
                развития и распространения вредных организмов и особо опасных
                вредных организмов, карантинных вредителей, болезней растении и
                сорняков.
              </p>
            </td>
          </tr>
          <tr>
            <td width="65" height="98">
              <p>
                <b>2-18&nbsp;</b>
              </p>
            </td>
            <td>
              <p>
                В Каратальском районе, осуществить расширение посевных
                площадей&nbsp; и применение капельного орошения,&nbsp;
                влагосберегающей технологии (1800 га); произвести выращивание
                многолетних насаждений плодово-ягодных культур и винограда;
                произвести рациональное использование площадей орошаемых земель
                и эффективное использование поливной воды; провести химическую
                борьбу на прогнозируемой площади развития и распространения
                вредных организмов и особо опасных вредных организмов,
                карантинных вредителей, болезней растении и сорняков.
              </p>
            </td>
          </tr>
          <tr>
            <td width="65" height="51">
              <p>
                <b>2-19&nbsp;</b>
              </p>
            </td>
            <td>
              <p>
                Диверсификация отрасли растениеводство путем расширения площади
                приоритетных сельскохозяйственных культур, создание сельских
                потребительских кооперативов по заготовке, хранению и
                переработке сельхозпродукции в Коксуском районе.
              </p>
            </td>
          </tr>
          <tr>
            <td colSpan="2" width="643">
              <p>
                <b>
                  Охрана лесов и растительности; фитомелиоративные мероприятия
                </b>
              </p>
            </td>
          </tr>
          <tr>
            <td width="65" height="94">
              <p>
                <b>3-1</b>
              </p>
            </td>
            <td>
              <p>
                Во всех государственных учреждениях лесного хозяйства
                разработать и согласовать с районными ЧС планы противопожарных
                мероприятий по взаимодействию при тушении пожаров, создать ДПД
                из числа работников крестьянских хозяйств, граничащих с
                гослесфондом, и местного населения. Провести практические
                занятия по изучению правил и тактики тушения пожаров и техники
                безопасности при тушении лесных пожаров совместно с
                противопожарными службами. В период высокой пожарной опасности в
                лесах запретить пребывание физических лиц и приостановить право
                лесопользования на территории государственного лесного фонда
                Алакольского, Саркандского, Аксуского и Райымбекского районов
              </p>
            </td>
          </tr>
          <tr>
            <td width="65">
              <p>
                <b>3-2&nbsp;</b>
              </p>
            </td>
            <td>
              <p>
                Разработать на основе инвентаризации имеющихся насаждений
                эффективных ассортиментов растений для озеленения городов
                Талдыкорган, Текели, Сарканд, Жаркент.
              </p>
            </td>
          </tr>
          <tr>
            <td width="65">
              <p>
                <b>3-3&nbsp;</b>
              </p>
            </td>
            <td>
              <p>
                Инвентаризация и зонирование земель лесного фонда с
                установлением назначения и режима использования и выделением
                территорий нуждающихся в охране в Карасайском, Илейском и
                Талгарском районах.
              </p>
            </td>
          </tr>
          <tr>
            <td width="65">
              <p>
                <b>3-4&nbsp;</b>
              </p>
            </td>
            <td>
              <p>
                Произвести оценку фактического состояния озеленения с целью
                дальнейшего увеличение площади зеленых насаждений в г.
                Талдыкорган.
              </p>
            </td>
          </tr>
          <tr>
            <td width="65" height="27">
              <p>
                <b>3-5&nbsp;</b>
              </p>
            </td>
            <td>
              <p>
                Запрещение вырубки древесно-кустарниковой растительности,
                проведение санитарной чистки и посадка саженцев
                древесно-кустарниковой растительности в нарушенных ландшафтах в
                горах Уйгурского, Ескельдинского и Райымбекского районов.
              </p>
            </td>
          </tr>
          <tr>
            <td width="65" height="9">
              <p>
                <b>3-6</b>
              </p>
            </td>
            <td>
              <p>
                Разработка проекта и строительство дендропарка на территории
                районов области.
              </p>
            </td>
          </tr>
          <tr>
            <td width="65" height="4">
              <p>
                <b>3-7&nbsp;</b>
              </p>
            </td>
            <td>
              <p>
                Обеспечить сохранность памятников природы - Ясеневой рощи
                (Уйгурский район).
              </p>
            </td>
          </tr>
          <tr>
            <td width="65" height="8">
              <p>
                <b>3-8&nbsp;</b>
              </p>
            </td>
            <td>
              <p>
                По всем районам области организовать защиту лесонасаждений от
                пожаров. Выработка предложений по экономической стимуляции
                частных землепользователей, сохраняющих и приумножающих зеленые
                насаждения.
              </p>
            </td>
          </tr>
          <tr>
            <td width="65" height="12">
              <p>
                <b>3-9&nbsp;</b>
              </p>
            </td>
            <td>
              <p>
                Увеличение площади лесов и проведение лесозащитных мероприятий в
                Алакольском, Коксуском, Балкашском, Илейском, Кербулакском
                районах.
              </p>
            </td>
          </tr>
          <tr>
            <td width="65" height="28">
              <p>
                <b>3-10&nbsp;</b>
              </p>
            </td>
            <td>
              <p>
                Во всех районах области провести инвентаризацию защитных
                лесополос; облесение склонов, оврагов, балок. Провести
                химическую обработку лесных насаждений от листогрызущих
                вредителей на территории Жамбылского, Карасайского, Талгарского
                и Райымбекского районов.
              </p>
            </td>
          </tr>
          <tr>
            <td width="65" height="8">
              <p>
                <b>3-11&nbsp;</b>
              </p>
            </td>
            <td>
              <p>
                Разработать мероприятия по нормированию и регулированию
                рекреационных нагрузок на пойменные и лесные ландшафты
                Жамбылского, Карасайского, Талгарского и Райымбекского районов.
              </p>
            </td>
          </tr>
          <tr>
            <td colSpan="2" width="643" height="18">
              <p>
                <b>
                  Мероприятия по охране и рациональному использованию водных
                  ресурсов
                </b>
              </p>
            </td>
          </tr>
          <tr>
            <td width="65" height="65">
              <p>
                <b>4-1</b>
              </p>
            </td>
            <td>
              <p>
                По всей территории области уменьшить объемы отведения
                коллекторно-дренажных вод в поверхностные водотоки, обеспечив
                активное развитие методов их повторного использования. Внедрение
                данных мероприятий позволит:
              </p>
              <p>
                1. Снизить объемы забора свежей воды из р.р. Или, Каратал, Шарын
                в среднем на 50%.
              </p>
              <p>
                2. Сократить объемы используемых удобрений, пестицидных
                препаратов.
              </p>
              <p>
                3. Снизить негативное воздействие отводимых
                коллекторно-дренажных вод на поверхностные, подземные воды,
                почвы, грунты.
              </p>
            </td>
          </tr>
          <tr>
            <td width="65" height="25">
              <p>
                <b>4-2&nbsp;</b>
              </p>
            </td>
            <td>
              <p>
                На территории Панфиловского, Уйгурского и Саркандского районов
                снизить негативное воздействие малых предприятий пищевой
                промышленности на объекты окружающей среды по специфическим
                загрязнителям, обеспечив локальную очистку до отведения сточных
                вод на поля фильтрации, в реки. Использовать очищенные стоки на
                повторное техническое применение.
              </p>
            </td>
          </tr>
          <tr>
            <td width="65" height="35">
              <p>
                <b>4-3&nbsp;</b>
              </p>
            </td>
            <td>
              <p>
                Режимные наблюдения за состоянием и рациональным использованием
                поверхностных вод области. Выполнить переоценку эксплуатационных
                запасов месторождений подземных вод по всей территории области.
              </p>
            </td>
          </tr>
          <tr>
            <td width="65" height="52">
              <p>
                <b>4-4&nbsp;</b>
              </p>
            </td>
            <td>
              <p>
                Дать анализ состояния государственного и ведомственного
                мониторинга подземных вод по областям и районам. Прогноз
                состояния подземных вод на очагах загрязнения подземных вод
                методом моделирования и разработка мероприятий по защите
                подземных вод от загрязнения на всей территории области.
              </p>
            </td>
          </tr>
          <tr>
            <td width="65" height="33">
              <p>
                <b>4-5&nbsp;</b>
              </p>
            </td>
            <td>
              <p>
                Создание единой базы данных по мониторингу подземных вод районов
                области. Совершенствование системы контроля и наблюдений.
              </p>
            </td>
          </tr>
          <tr>
            <td width="65" height="38">
              <p>
                <b>4-6&nbsp;</b>
              </p>
            </td>
            <td>
              <p>
                Выполнить работы по очистке, регулированию и охране каналов,
                лотков, водохранилищ; отремонтировать гидротехнические
                сооружения и гидрометрические посты в районах областей.
              </p>
            </td>
          </tr>
          <tr>
            <td width="65" height="46">
              <p>
                <b>4-7&nbsp;</b>
              </p>
            </td>
            <td>
              <p>
                Комплексное обследование территории двух областей по уточнению и
                выявлению новых очагов загрязнения поверхностных вод и факторов
                их загрязнения.
              </p>
            </td>
          </tr>
          <tr>
            <td width="65">
              <p>
                <b>4-8&nbsp;</b>
              </p>
            </td>
            <td>
              <p>
                Капитальный ремонт канализационных сетей и очистных сооружений,
                разработать ПСД следующих населенных пунктов:
              </p>
              <p>- п. Карабулак Ескельдинского района;&nbsp;&nbsp;&nbsp;</p>
              <p>- г. Жаркент Панфиловского района;</p>
              <p>- г. Текели.</p>
            </td>
          </tr>
          <tr>
            <td width="65" height="12">
              <p>
                <b>4-9&nbsp;</b>
              </p>
            </td>
            <td>
              <p>
                Осуществить ремонт, реконструкцию и строительство водопроводных
                сетей
              </p>
            </td>
          </tr>
          <tr>
            <td width="65" height="82">
              <p>
                <b>4-10&nbsp;</b>
              </p>
            </td>
            <td>
              <p>
                Ежегодная реконструкция водопроводных и канализационных сетей
                бестраншейным методом, с разрушением существующих трубопроводов
                и их заменой полиэтиленовыми трубами; перевод технологии очистки
                воды с применением хлорного железа на менее токсичные коагулянты
                и флокулянты; проведение реконструкции фильтровальной станции
                1-очереди на головных очистных сооружениях в городов
                Талдыкорган, Жаркент, Текели для сокращения технологических
                расходов.
              </p>
            </td>
          </tr>
          <tr>
            <td width="65" height="34">
              <p>
                <b>4-11&nbsp;</b>
              </p>
            </td>
            <td>
              <p>
                Ежегодная реконструкция водопроводных и канализационных сетей
                бестраншейным методом, с разрушением существующих трубопроводов
                и их заменой полиэтиленовыми трубами; перевод технологии очистки
                воды с применением хлорного железа на менее токсичные коагулянты
                и флокулянты.
              </p>
            </td>
          </tr>
          <tr>
            <td colSpan="2" width="643" height="11">
              <p>
                <b>
                  Мероприятия по предупреждению загрязнения приземного
                  атмосферного воздуха
                </b>
              </p>
            </td>
          </tr>
          <tr>
            <td width="65" height="54">
              <p>
                <b>5-1</b>
              </p>
            </td>
            <td>
              <p>
                На территории всех районов осуществить мониторинг за состоянием
                атмосферного воздуха, подземных вод и почв в населенных пунктах,
                прилегающих к объектам добывающего, перерабатывающего
                промышленного сектора, стихийных свалок промышленных и твердых
                бытовых отходов.
              </p>
            </td>
          </tr>
          <tr>
            <td width="65" height="37">
              <p>
                <b>5-2&nbsp;</b>
              </p>
            </td>
            <td>
              <p>
                Ежегодно тысячные тонны отходов и мусора складируются на
                мусоросвалках. В Алакольском районе необходимо строительство
                завода по переработке ТБО, так как ежегодно тысячные тонны
                отходов и мусора складируются на мусоросвалках.
              </p>
            </td>
          </tr>
          <tr>
            <td width="65" height="17">
              <p>
                <b>5-3&nbsp;</b>
              </p>
            </td>
            <td>
              <p>
                Для минимизации загрязнения атмосферного воздуха&nbsp; &nbsp;
                выбросами автотранспорта рекомендуется:
              </p>
              <p>
                - создание специализированных постов по проверке автотранспорта
                на соответствие стандартам Евро-2;&nbsp;&nbsp;
              </p>
              <p>- расширение сетей специализированных постов;&nbsp;&nbsp;</p>
              <p>
                - снижение выбросов от автотранспорта на территории близ городов
                Талдыкорган, Жаркент.
              </p>
            </td>
          </tr>
          <tr>
            <td width="65" height="18">
              <p>
                <b>5-4&nbsp;</b>
              </p>
            </td>
            <td>
              <p>
                Принять меры по использованию бензоспиртов для автотранспорта
                (кислородное обогащение топлива), тем самым снизить выбросы
                угарного газа, бензола и других токсичных компонентов (по всей
                территории области).
              </p>
            </td>
          </tr>
          <tr>
            <td width="65" height="58">
              <p>
                <b>5-5&nbsp;</b>
              </p>
            </td>
            <td>
              <p>
                Выполнить количественную оценку выбросов парниковых газов,
                разработать сценарии эмиссий парниковых газов, разработать
                стратегию по снижению эмиссий парниковых газов, подготовить
                условия для создания национальной системы лицензирования квот на
                выбросы парниковых газов, наладить систему мониторинга и
                отчетности по эмиссиям/стоку парниковых газов (по всей
                территории).
              </p>
            </td>
          </tr>
          <tr>
            <td width="65" height="20">
              <p>
                <b>5-6&nbsp;</b>
              </p>
            </td>
            <td>
              <p>
                Для качественной&nbsp; оценки состояния воздушного бассейна
                области необходимо восстановить ранее существовавшие
                стационарные посты&nbsp; наблюдения и дополнительно создать
                новые.
              </p>
            </td>
          </tr>
          <tr>
            <td width="65" height="21">
              <p>
                <b>5-7&nbsp;</b>
              </p>
            </td>
            <td>
              <p>
                Совершенствовать улавливание оксидов азота из дымовых газов на
                крупных предприятиях области путем усовершенствования
                интенсификации производства.
              </p>
            </td>
          </tr>
          <tr>
            <td width="65" height="87">
              <p>
                <b>5-8&nbsp;</b>
              </p>
            </td>
            <td>
              <p>
                Установить на предприятиях теплоэнергетики газоочистные
                сооружения (эмульгаторы для ТЭЦ-1 и ТЭЦ-2); полная газификация
                частного сектора; использование высокоэффективного
                пылегазоочистного оборудования на предприятиях города, вынос
                крупных предприятий-загрязнителей воздуха за территорию г.
                Алматы.
              </p>
              <p>
                Для минимизации влияния на загрязнение воздушного бассейна г.
                Алматы выбросов вредных веществ в атмосферу промышленных
                предприятий необходимо осуществить перевод котельных ТЭЦ-2 АО
                «АЛЭС» на природный газ, частично поэтапно вывести за пределы
                города предприятия
              </p>
            </td>
          </tr>
          <tr>
            <td width="65" height="138">
              <p>
                <b>5-9&nbsp;</b>
              </p>
            </td>
            <td>
              <p>
                Разработать и внедрить проект системы контроля токсичности
                отработавших газов автотранспортных средств по стандартам ЕВРО в
                г. Алматы.
              </p>
              <p>
                Разработать ТЭО, ПСД организации системы регулирования въезда
                иногороднего транспорта по нормам токсичности и внешнему
                состоянию автотранспорта.
              </p>
              <p>
                Разработать нормативный документ, запрещающий использование
                твердого топлива в г. Алматы с зольностью свыше 26% и
                содержанием серы 0,55%.
              </p>
              <p>
                Установка вдоль автомагистралей электронных билбордов для
                оперативного оповещения населения информацией о состоянии
                качества атмосферного воздуха.
              </p>
              <p>
                Контроль качества автомоторного топлива (ГСМ), поступающего и
                реалиизуемого в городе Алматы.
              </p>
            </td>
          </tr>
          <tr>
            <td colSpan="2" width="643" height="1">
              <p>
                <b>
                  Мероприятия по предупреждению и ликвидации загрязнения
                  почвенного покрова
                </b>
              </p>
            </td>
          </tr>
          <tr>
            <td width="65" height="33">
              <p>
                <b>6-1</b>
              </p>
            </td>
            <td>
              <p>
                Создать единую систему регионального экологического мониторинга
                и контроля за загрязнением почв вблизи промышленных предприятий,
                месторождений области.
              </p>
            </td>
          </tr>
          <tr>
            <td width="65" height="45">
              <p>
                <b>6-2&nbsp;</b>
              </p>
            </td>
            <td>
              <p>
                Провести ликвидацию стихийных свалок, организацию стационарных
                полигонов хранения бытовых отходов и разработку мероприятий по
                их утилизации. Наибольшее количество стихийных свалок находятся
                в сельских населенных пунктах Карасайского и Енбекшиказахского
                районов.
              </p>
            </td>
          </tr>
          <tr>
            <td width="65">
              <p>
                <b>6-3&nbsp;</b>
              </p>
            </td>
            <td>
              <p>
                Произвести разработку проектов и строительство полигонов для
                захоронения промышленных и токсичных отходов в г. Текели.
              </p>
            </td>
          </tr>
          <tr>
            <td width="65">
              <p>
                <b>6-4&nbsp;</b>
              </p>
            </td>
            <td>
              <p>
                Строительство полигона для складирования твердо-бытовых отходов
                г. Талдыкорган.
              </p>
            </td>
          </tr>
          <tr>
            <td width="65" height="46">
              <p>
                <b>6-5&nbsp;</b>
              </p>
            </td>
            <td>
              <p>
                Разработка проектов строительство новых полигонов с реализацией
                проекта направленного на утилизацию твердых бытовых отходов в
                районных центрах всех административных районов.
              </p>
            </td>
          </tr>
          <tr>
            <td width="65" height="24">
              <p>
                <b>6-6&nbsp;</b>
              </p>
            </td>
            <td>
              <p>
                Проведение экологического мониторинга земель Кербулакского
                района. Осуществление лабораторного анализа существующих
                поступлений загрязнителей в почвы.
              </p>
            </td>
          </tr>
          <tr>
            <td width="65" height="1">
              <p>
                <b>6-7&nbsp;</b>
              </p>
            </td>
            <td>
              <p>
                Строительство полигона для складирования твердо-бытовых отходов
                п. Карабулак Ескельдинского района.
              </p>
            </td>
          </tr>
          <tr>
            <td width="65" height="17">
              <p>
                <b>6-8&nbsp;</b>
              </p>
            </td>
            <td>
              <p>
                Проектирование, ликвидация несанкционированных свалок г.
                Талдыкорган и пригородных поселков с рекультивацией нарушенных
                земель.
              </p>
            </td>
          </tr>
          <tr>
            <td width="65">
              <p>
                <b>6-9&nbsp;</b>
              </p>
            </td>
            <td>
              <p>
                Ликвидация несанкционированных свалок области с рекультивацией
                нарушенных земель в районных центрах.
              </p>
            </td>
          </tr>
          <tr>
            <td width="65" height="24">
              <p>
                <b>6-10&nbsp;</b>
              </p>
            </td>
            <td>
              <p>
                Разработать проекты по захоронению радиоактивных отвалов
                (отходов). Проведение захоронения радиоактивных отвалов
                Панфиловского уранового месторождения. Предотвращение
                радиоактивного загрязнения окружающей среды.
              </p>
            </td>
          </tr>
          <tr>
            <td width="65" height="28">
              <p>
                <b>6-11&nbsp;</b>
              </p>
            </td>
            <td>
              <p>
                Разработка проектно-сметной документации рекультивации
                отработанного хвостохранилища&nbsp; Текелийского
                свинцово-цинкового комбината.
              </p>
            </td>
          </tr>
          <tr>
            <td width="65" height="58">
              <p>
                <b>6-12&nbsp;</b>
              </p>
            </td>
            <td>
              <p>
                Провести инвентаризацию очагов загрязнения устаревшими
                пестицидами и стойкими органическими загрязнителями в Талгарском
                районе. Ликвидация очагов загрязнения устаревшими пестицидами
                для предотвращения загрязнения окружающей среды и ликвидации
                отрицательного воздействия на здоровье человека в Талгарском
                районе.
              </p>
            </td>
          </tr>
          <tr>
            <td width="65">
              <p>
                <b>6-13&nbsp;</b>
              </p>
            </td>
            <td>
              <p>
                Организация экологического мониторинга земель области.
                Осуществление лабораторного контроля существующих и возможных
                поступлений загрязнителей в почвы; обустройство экологических
                площадок.
              </p>
            </td>
          </tr>
          <tr>
            <td colSpan="2" width="643" height="33">
              <p>
                <b>Противоэрозионные ипротиводефляционные мероприятия</b>
              </p>
            </td>
          </tr>
          <tr>
            <td width="65" height="45">
              <p>
                <b>8-1</b>
              </p>
            </td>
            <td>
              <p>
                Проведение противодефляционных мероприятий на песчаных массивах
                Мойынкум Балкашского района и Каракум Панфиловского района.
                Организовать противосолонцовые мероприятия в Уйгурском,
                Балкашском и Панфиловском районах.
              </p>
            </td>
          </tr>
          <tr>
            <td width="65">
              <p>
                <b>8-2&nbsp;</b>
              </p>
            </td>
            <td>
              <p>
                По районам произвести противодефляционные мероприятия на
                массивах. Закрепление песков путем облесения.
              </p>
            </td>
          </tr>
          <tr>
            <td width="65" height="37">
              <p>
                <b>8-3&nbsp;</b>
              </p>
            </td>
            <td>
              <p>
                Проведение наблюдений за эоловым морфогенезом, его динамикой,
                геодинамическими процессами на территории Илейского,
                Кербулакского и Балкашского района.
              </p>
            </td>
          </tr>
          <tr>
            <td width="65" height="43">
              <p>
                <b>8-4&nbsp;</b>
              </p>
            </td>
            <td>
              <p>
                В Жамбылском и Карасайском районах произвести
                противодефляционные мероприятия на песчаных массивах Таукум,
                Сарытаукум. Закрепление песков путем облесения на территории
                Жамбылского района.
              </p>
            </td>
          </tr>
          <tr>
            <td width="65" height="22">
              <p>
                <b>8-5&nbsp;</b>
              </p>
            </td>
            <td>
              <p>
                Выполнить инвентаризацию и картирование эрозионно-опасных земель
                в Алакольском, Кербулакском, Талгарском, Жамбылском и
                Райымбекском районах.
              </p>
            </td>
          </tr>
          <tr>
            <td width="65" height="49">
              <p>
                <b>8-6&nbsp;</b>
              </p>
            </td>
            <td>
              <p>
                Рекомендуется проведение агрономических противоэрозионных
                мероприятий на пахотных землях (регулирование поверхностного
                стока воды путем вспашки и посева сельскохозяйственных культур
                поперек склона) во всех районах области.
              </p>
            </td>
          </tr>
          <tr>
            <td width="65" height="40">
              <p>
                <b>8-7&nbsp;</b>
              </p>
            </td>
            <td>
              <p>
                Выполнить детальное ландшафтно-геохимическое районирование
                Талгарского, Ескельдинского, Аксуского, Саркандского и
                Райымбекского районов, организовать агрохимическое картирование
                почв.
              </p>
            </td>
          </tr>
          <tr>
            <td width="65" height="30">
              <p>
                <b>8-8&nbsp;</b>
              </p>
            </td>
            <td>
              <p>
                Выявить потенциально-опасные очаги нарушения почвенного покрова
                и перепланировать поля с учетом рельефа местности на территории
                Талгарского района.
              </p>
            </td>
          </tr>
          <tr>
            <td width="65" height="30">
              <p>
                <b>8-9&nbsp;</b>
              </p>
            </td>
            <td>
              <p>
                На основе инвентаризации и зонирования пойменных и лесных
                экосистем территории установить назначение и режим использования
                территорий, установить границы водоохранных зон и выделить
                территории, нуждающиеся в охране.
              </p>
            </td>
          </tr>
          <tr>
            <td width="65">
              <p>
                <b>8-10&nbsp;</b>
              </p>
            </td>
            <td>
              <p>
                Восстановление пустынных лесов путем посева семян саксаула на
                песчаных массивах Мойынкум на территории Балкашского района и
                Каракум на территории Панфиловского района.
              </p>
            </td>
          </tr>
          <tr>
            <td width="65" height="45">
              <p>
                <b>8-11&nbsp;</b>
              </p>
            </td>
            <td>
              <p>
                Выборочное сенокошение рекомендовано в природных комплексах,
                характеризующихся удовлетворительным ландшафтно-экологическим
                состоянием и подразумевает учет сезона и агроклиматических
                условия текущего года (во всех районах).
              </p>
            </td>
          </tr>
          <tr>
            <td width="65" height="10">
              <p>
                <b>8-12&nbsp;</b>
              </p>
            </td>
            <td>
              <p>
                Запрет сенокошения в природных комплексах, подвергшихся
                значительной деградации, на территориях Райымбекского,
                Коксуского, Кербулакского, Каратальского и Панфиловского
                районов.
              </p>
            </td>
          </tr>
          <tr>
            <td colSpan="2" width="643">
              <p>
                <b>
                  Развитие и создание сети особо охраняемых территорий (ООПТ).
                </b>
              </p>
            </td>
          </tr>
          <tr>
            <td width="65">
              <p>
                <b>12-1</b>
              </p>
            </td>
            <td>
              <p>
                Реализовать ландшафтно-экологические экспедиции, которые
                позволят осуществить инвентаризацию объектов повышенного
                биологического и ландшафтного разнообразия горных территорий,
                разработать единую информационную базу данных биологического и
                ландшафтного разнообразия территории
              </p>
            </td>
          </tr>
          <tr>
            <td width="65" height="5">
              <p>
                <b>12-2&nbsp;</b>
              </p>
            </td>
            <td>
              <p>
                Развитие платного научно-экологического туризма. Создание в
                интернете банка данных по современной фауне, палеофауне и особо
                охраняемых природных территории (ООПТ) (НП Шарын, Алакольский
                заповедник, Верхнекоксуский заказник, Лепсинский заказник,
                Токтинский заказник). Реклама научно-экологического туризма в
                области.
              </p>
            </td>
          </tr>
          <tr>
            <td width="65">
              <p>
                <b>12-3&nbsp;</b>
              </p>
            </td>
            <td>
              <p>
                Реализовать работы по сохранению животных и птиц в местах
                повышенного техногенного воздействия и возникновения аварийных
                ситуаций области.
              </p>
            </td>
          </tr>
          <tr>
            <td width="65">
              <p>
                <b>12-4&nbsp;</b>
              </p>
            </td>
            <td>
              <p>
                Охрана диких животных, занесенных в Красную книгу Республики
                Казахстан (барс, рысь, медведь, архар, джейран, семиречинский
                лягушкозуб).
              </p>
            </td>
          </tr>
          <tr>
            <td width="65">
              <p>
                <b>12-5&nbsp;</b>
              </p>
            </td>
            <td>
              <p>
                За нарушение природоохранного законодательства&nbsp;
                рекомендуется ужесточение системы штрафов и платежей.
              </p>
            </td>
          </tr>
          <tr>
            <td width="65">
              <p>
                <b>12-6&nbsp;</b>
              </p>
            </td>
            <td>
              <p>
                Инвентаризация доминирующих видов животных области. Учет
                животных с целью сохранения и увеличения видового состава.
              </p>
            </td>
          </tr>
          <tr>
            <td width="65">
              <p>
                <b>12-7&nbsp;</b>
              </p>
            </td>
            <td>
              <p>
                Создание питомников редких, исчезающих видов животных.
                Поддержание численности и сохранение генофонда редких и
                исчезающих видов фауны области.
              </p>
            </td>
          </tr>
          <tr>
            <td width="65">
              <p>
                <b>12-8&nbsp;</b>
              </p>
            </td>
            <td>
              <p>
                Провести научные работы по сохранению генофонда и
                биоразнообразия путем составления паспорта рыбохозяйственных
                водоемов местного значения в области.
              </p>
            </td>
          </tr>
        </table>
        <p>&nbsp;</p>
        <p lang="ru-RU">
          <br />
        </p>
        <p>
          <b>
            Перечень рекомендательных природоохранных мероприятий по
            предотвращению развития процессов опустынивания в
            природно-сельскохозяйственных системах для сельских территорий
            Кызылординской области
          </b>
        </p>
        <p lang="ru-RU">
          <br />
        </p>
        <table cellPadding="1" cellSpacing="0">
          <col />
          <col />
          <tr valign="top">
            <td>
              <p>Номерация &nbsp;</p>
            </td>
            <td>
              <p>
                Направление деятельности, виды&nbsp; и содержание мероприятий
              </p>
            </td>
          </tr>
          <tr>
            <td colSpan="2" width="644" height="4">
              <p>
                <b>Охрана и рациональное использование пастбищ и сенокосов</b>
              </p>
            </td>
          </tr>
          <tr>
            <td height="4">
              <p>1-1</p>
            </td>
            <td>
              <p>
                Осуществлять регулирование выпаса скота с учетом типов пастбищ,
                пастбищных нагрузок на экологическую среду. Запретить выпас и
                произвести фито мелиорацию с одновременным подсевом пастбищных
                растений на сильно деградированных угодьях, в том числе,
                присельских пастбищах населенных пунктов — Казали, Айтеке би.
                Жалагаш, Теренозек и др.&nbsp;&nbsp;
              </p>
            </td>
          </tr>
          <tr>
            <td height="4">
              <p>1-2</p>
            </td>
            <td>
              <p>
                Создания устойчивой кормовой базы путем увеличения посевных
                площадей под кормовыми культурами&nbsp;&nbsp;
              </p>
            </td>
          </tr>
          <tr>
            <td height="4">
              <p>1-3</p>
            </td>
            <td>
              <p>
                Провести инвентаризацию обводнительных сооружений, строительство
                новых колодцев с использованием современных технологий,
                увеличить сеть искусственных мест водопоя на всей территории
              </p>
            </td>
          </tr>
          <tr>
            <td height="4">
              <p>1-4</p>
            </td>
            <td>
              <p>
                Разработать схемы водоснабжения водопойных пунктов на отгонных
                пастбищах (локальная схема, групповая схема, подвоз воды и т.
                д.)
              </p>
            </td>
          </tr>
          <tr>
            <td height="4">
              <p>1-5</p>
            </td>
            <td>
              <p>
                Осуществить фитомелиоративные и оптимизационные мероприятия для
                ландшафтов пастбищного использования в Кармакшынском, Аральском,
                Жалагашском административных районах
              </p>
            </td>
          </tr>
          <tr>
            <td colSpan="2" width="644">
              <p>
                <b>Мероприятия по улучшению состояния пашни</b>
              </p>
            </td>
          </tr>
          <tr>
            <td height="30">
              <p>2-2</p>
            </td>
            <td>
              <p>
                Внедрение новых технологий возделывания сельскохозяйственных
                культур с применением прогрессивных, влагоресурсо-сберегающих
                технологий капельного&nbsp; орошения на орошаемой пашне в
                Кызылординской ПСС (Шиелийский,&nbsp; Жалагашский,
                Жанакорганском, Сырдариинском и Аральский административные&nbsp;
                районы)&nbsp;&nbsp;
              </p>
            </td>
          </tr>
          <tr>
            <td>
              <p>2-3</p>
            </td>
            <td>
              <p>
                Провести реконструкцию Жанакоргано-Шиелийской, Тогускенской,
                Левобережных Кызылординской и Казалинской, Правобережных
                Кызылординской и Казалинской оросительно-обводнительных систем;
                оснащение оросительных систем гидротехническими сооружениями и
                доведения их количества до «нормативно-необходимой» (во всех
                административных районах)&nbsp;&nbsp;
              </p>
            </td>
          </tr>
          <tr>
            <td>
              <p>2-4</p>
            </td>
            <td>
              <p>
                Введение почвозащитных и водосберегающих технологий полива (на
                массивах орошения - Казалинский, Кызылординский,
                Жанакоргано-Шиелийский). Строгое соблюдение норм полива на всей
                территории Кызылординской ПСС&nbsp;&nbsp;
              </p>
            </td>
          </tr>
          <tr>
            <td>
              <p>2-5</p>
            </td>
            <td>
              <p>
                Произвести поэтапное сокращение рисосеющей отрасли в бассейне р.
                Сырдария до ее экологически безопасного уровня (Казалинский,
                Сырдариинский, Шиелийский, Жанакорганский административные
                районы). Организационно-хозяйственные мероприятия и
                рекультивация для длительно используемых пахотных земель
                (перепланировка, выравнивание поверхности поля, внесение
                органических и минеральных удобрений) в Шиелинском и
                Жанакорганском районах.
              </p>
            </td>
          </tr>
          <tr>
            <td>
              <p>2-6</p>
            </td>
            <td>
              <p>
                Рекультивация деградированной пашни с применением
                агропроизводственных и&nbsp; агромелиоративных мероприятий
                (безотвальная обработка, глубокая вспашка, севооборот
                многолетних трав) (Казалинский, Сырдариинский, Шиелийский,
                Жанакорганский административные районы)
              </p>
            </td>
          </tr>
          <tr>
            <td>
              <p>2-7</p>
            </td>
            <td>
              <p>
                Прекратить распашку земель и использование ядохимикатов в
                пределах&nbsp; прибрежных водоохранных зон и водозаборов
                (Казалинский, Сырдариинский,&nbsp; Шиелийский, Жанакорганский
                административные районы)
              </p>
            </td>
          </tr>
          <tr>
            <td>
              <p>2-8</p>
            </td>
            <td>
              <p>
                Комплекс мероприятий, направленных на восстановление и
                улучшение&nbsp; продуктивности нарушенной пашни, с целью
                восстановления плодородия,&nbsp; включающих агротехнические и
                фитомелиоративные мероприятия (Казалинский,&nbsp; Сырдариинский,
                Шиелийский, Жанакорганский районы)&nbsp;&nbsp;
              </p>
            </td>
          </tr>
          <tr>
            <td>
              <p>2-9</p>
            </td>
            <td>
              <p>
                Комплексные агротехнические мероприятия на пашне:&nbsp;&nbsp;
              </p>
              <p>
                -противоэрозионные мероприятия в сочетании с правильным
                размещением&nbsp; севооборотов, защитных лесонасаждений и
                гидротехнических сооружений;&nbsp;&nbsp;
              </p>
              <p>
                - распространение почвозащитных полевых и лугопастбищных
                севооборотов;&nbsp;&nbsp;
              </p>
              <p>
                - укрепление, облесение оврагов, сильно эродированных
                склонов;&nbsp;&nbsp;
              </p>
              <p>
                - регулирование пастьбы скота в балках, на крутых склонах, на
                эрозионноопасных почвах (Казалинский, Сырдариинский,
                Жанакорганский районы)
              </p>
            </td>
          </tr>
          <tr>
            <td colSpan="2" width="644">
              <p>
                <b>
                  Охрана лесов и растительности; фитомелиоративные мероприятия
                </b>
              </p>
            </td>
          </tr>
          <tr>
            <td>
              <p>3-1</p>
            </td>
            <td>
              <p>
                Разработка мероприятий по нормированию и регулированию
                рекреационных нагрузок на пойменные и лесные ландшафты (г.а.
                Кызылорда, Аральский, Казалинский, Кармакшынский, Жалагашский,
                Жанакорганский, Сыр дар минский, Шиелийский административные
                районы)
              </p>
            </td>
          </tr>
          <tr>
            <td>
              <p>3-3</p>
            </td>
            <td>
              <p>
                Регулирование сроков и норм косьбы в долине р. Сырдария.
                Применение выборочного сенокошение в природных комплексах,
                характеризующихся удовлетворительным ландшафтно-экологическим
                состоянием природно- сельскохозяйственной системы
              </p>
            </td>
          </tr>
          <tr>
            <td>
              <p>3-4</p>
            </td>
            <td>
              <p>
                Ограничение вырубки пустынных кустарников (саксаула, тамариска,
                жузгуна, и т.д.) на топливо, так как вырубка кустарников
                приводит к формированию умеренно и сильно деградированных
                пастбищ на эоловых равнинах и развитию плоскостного смыва на
                предгорных и горных пастбищах, расположенных на суглинках и
                лессах (Казалинский, Сырдариинский, Шиелийский, Жанакорганский
                административные районы)
              </p>
            </td>
          </tr>
          <tr>
            <td>
              <p>3-5</p>
            </td>
            <td>
              <p>
                В пределах поймы Сырдария и других водных систем начать
                восстановление тугайных зарослей. Данное мероприятие позволит
                сохранить оставшиеся и восстановить ранее существующие
                естественные интразональные гидроморфные ландшафты, предупредить
                процессы заболачивания, а в конечном итоге - сохранить и
                восстановить биоразнообразие дельтовых экосистем
              </p>
            </td>
          </tr>
          <tr>
            <td>
              <p>3-6</p>
            </td>
            <td>
              <p>
                Создания растительного покрова (саксаул, сарсазан и гребенщик)
                на осушенном дне Аральского моря с использованием галофитов для
                предотвращения эрозионных процессов
              </p>
            </td>
          </tr>
          <tr>
            <td>
              <p>4-1</p>
            </td>
            <td>
              <p>
                Рекомендуется совершенствовать эффективность водообеспечивающей
                и водоохранной деятельности на всей территории ПСС
              </p>
            </td>
          </tr>
          <tr>
            <td>
              <p>4-2</p>
            </td>
            <td>
              <p>
                Осуществлять регулярный мониторинг по качественному состоянию
                вод реки Сырдария и Аральского моря и выявлению источников
                загрязнения вод
              </p>
            </td>
          </tr>
          <tr>
            <td>
              <p>4-3</p>
            </td>
            <td>
              <p>
                Осуществить инвентаризацию, качественную и техническую оценку
                источников подземных вод, осу ществить мониторинг на водозаборах
                подземных вод расположенных на территории месторождений в
                Аральском, Казалинском, Кармакшынском, Жалагашском,
                Жанакорганском, Сырдариинском, Шиелийском административных
                районах
              </p>
            </td>
          </tr>
          <tr>
            <td>
              <p>4-4</p>
            </td>
            <td>
              <p>
                Изыскать альтернативные варианты использования подземных вод
                повышенной минерализации для снижения использования подземных
                вод хорошего качества для производственных нужд промышленных
                объектов на территории природно- сельскохозяйственной системы
              </p>
            </td>
          </tr>
          <tr>
            <td>
              <p>4-5</p>
            </td>
            <td>
              <p>
                Осуществить работы по установлению водоохранных зон и полос
                озера Камыстыбас Аральского района и вдоль рек Сырдария,
                Куандария, а также осуществлять контроль за их состоянием и
                выполнением водоохранных мероприятий
              </p>
            </td>
          </tr>
          <tr>
            <td>
              <p>4-6</p>
            </td>
            <td>
              <p>
                Провести обследования для восстановления защитных дамб: в
                Жанакорганском районе на коллекторе близ населенного пункта
                Байкенже и на участке Балапан тобе защитной дамбы
                Жанакорган-Томенарык в Шиелийском районе; на защитной дамбе
                Актам - Мешеуiлi» в Сырдариинском районе, на защитной дамбе на
                участке «Жақапбай» в Казалинском районе; на правобережной
                защитной дамбе на участке Отебас; на правобережной защитной
                дамбе на участках Шитубек, Мортьқ в Аральском районе
              </p>
            </td>
          </tr>
          <tr>
            <td>
              <p>4-7</p>
            </td>
            <td>
              <p>
                Осуществить работы по восстановлению вышедших из строя
                ирригационной коллекторно-дренажной сети в селах Байконыре,
                Торетаме и Жусалах Кармакшинского района; магистральных каналов
                Теликольский, Шиелийский, Келинтобинский в Шиелийском районе
              </p>
            </td>
          </tr>
          <tr>
            <td>
              <p>4-8</p>
            </td>
            <td>
              <p>
                Провести мероприятия по улучшению экологического состояния озера
                Тайпакколь и Кандыаральской системы озер Жанакорганского
                административного района для улучшения экологического состояния
              </p>
            </td>
          </tr>
          <tr>
            <td>
              <p>4-9</p>
            </td>
            <td>
              <p>
                Провести капитальный ремонт водохранилища «Кыраш»
                Жанакорганского административного района
              </p>
            </td>
          </tr>
          <tr>
            <td>
              <p>4-10</p>
            </td>
            <td>
              <p>
                Рекомендуется осуществить разведку и уточнение запасов подземных
                вод на территории Кызылординской ПСС. Определить потребности в
                развитии сети питьевого водоснабжения, произвести полную
                инвентаризацию и паспортизацию водопроводных, водоочистных и
                канализационных сооружений в каждом населенном пункте
                природно-сельскохозяйственной системы
              </p>
            </td>
          </tr>
          <tr>
            <td>
              <p>4-11</p>
            </td>
            <td>
              <p>
                Осуществить реконструкцию и дальнейшее строительство Арало-
                Сарыбулакского группового водопровода для подключения к нему
                населенных пунктов
              </p>
            </td>
          </tr>
          <tr>
            <td>
              <p>4-12</p>
            </td>
            <td>
              <p>
                Осуществить: реконструкцию и строительство
                канализационно-очистных сооружений, строительство
                водоопреснительных и водоочистных установок; строительство
                экономически эффективных пунктов водоподготовки в населенных
                пунктах с высокой минерализацией подземных вод на всей
                территории природно- сельскохозяйственной системы
              </p>
            </td>
          </tr>
          <tr>
            <td>
              <p>4-13</p>
            </td>
            <td>
              <p>
                Осуществлять строгий контроль за качеством питьевой воды на
                соответствие санитарным нормам в сельских населенных пунктах
                Коне-Буген, Бекбаул, разъезд 93 Укилисай Аральского района, где
                используют привозную воду из-за малочисленности населения, и
                строительство систем водоснабжения считается экономически
                нецелесообразным
              </p>
            </td>
          </tr>
          <tr>
            <td>
              <p>4-14</p>
            </td>
            <td>
              <p>
                Создать водоохранные зоны, полосы и зоны санитарной охраны
                источников питьевого водоснабжения (территории вокруг
                водозаборов, скважин и резервуаров и т.д.)
              </p>
            </td>
          </tr>
          <tr>
            <td colSpan="2" width="644">
              <p>
                <b>
                  Мероприятия по предупреждению и ликвидации загрязнения
                  почвенного покрова
                </b>
              </p>
            </td>
          </tr>
          <tr>
            <td>
              <p>6-1</p>
            </td>
            <td>
              <p>
                Провести агрохимическое обследования земель
                сельскохозяйственного назначения на территории
                природно-сельскохозяйственной системы
              </p>
            </td>
          </tr>
          <tr>
            <td>
              <p>6-2</p>
            </td>
            <td>
              <p>
                В структуре посевных площадей увеличить площади посевов
                многолетних трав в Аральском, Казалинском, Кармакшынском,
                Жалагашском, Жанакорганском, Сырдариинском, Шиелийском
                административных районах
              </p>
            </td>
          </tr>
          <tr>
            <td>
              <p>6-3</p>
            </td>
            <td>
              <p>
                Внесение минеральных и различных видов органических удобрений
                под высеваемые культуры
              </p>
            </td>
          </tr>
          <tr>
            <td>
              <p>6-4</p>
            </td>
            <td>
              <p>
                Создать единую систему регионального экологического мониторинга
                и контроля за загрязнением почв в Аральском, Казалинском,
                Кармакшынском, Жалагашском, Жанакорганском, Сырдариинском,
                Шиелийском административных районах
              </p>
            </td>
          </tr>
          <tr>
            <td>
              <p>6-5</p>
            </td>
            <td>
              <p>
                Разработать программу улучшения экологического состояния
                нарушенных земель, адаптированную к ландшафтно-экологическим
                условиям
              </p>
            </td>
          </tr>
          <tr>
            <td>
              <p>6-7</p>
            </td>
            <td>
              <p>
                Внесение минеральных и различных видов органических удобрений
                под&nbsp; высеваемые сельскохозяйственные культуры в
                Жалагашском, Жанакорганском, Сырдариинском и Шиелийском
                административных районах
              </p>
            </td>
          </tr>
          <tr>
            <td>
              <p>6-8</p>
            </td>
            <td>
              <p>
                Рекультивация нарушенных земельных участков, своевременное
                вовлечение земель в севооборот в Аральском и Казалинском
                административных районах
              </p>
            </td>
          </tr>
          <tr>
            <td colSpan="2" width="644">
              <p>
                <b>Противоэрозионные и противодефляционные мероприятия</b>
              </p>
            </td>
          </tr>
          <tr>
            <td>
              <p>8-1</p>
            </td>
            <td>
              <p>
                Осуществить закрепление движущихся песков фашинами, устилочными
                матами различной конструкции, заборами-пескоуловителями,
                биодеструкторами и путем посадки растений видов-пескоукрепителей
                (жузгун, астрагалы, саксаул и др.) на пастбищах песчаных
                массивов Малые Барсуки, Приаральские Каракумы, Кызылкум, вдоль
                автомобильных дорог и трубопроводов
              </p>
            </td>
          </tr>
          <tr>
            <td>
              <p>8-2</p>
            </td>
            <td>
              <p>
                Провести посадку растений-галофитов (сарсазан, солянка, тамариск
                и др.) для борьбы с выветриванием солей (Казалинский,
                Сырдариинский, Шиелийский, Жанакорганский административные
                районы).
              </p>
            </td>
          </tr>
          <tr>
            <td>
              <p>8-3</p>
            </td>
            <td>
              <p>
                Создание защитных лесополос вокруг пахотных массивов для
                предовращения ветровой эрозии на полях. Для повышения
                снегозапаса на полях и влагозапаса в почвах сельскохозяйственных
                угодий рекомендовать создание лесополос из тополя, дуба,
                карагача, которые располагаются перпендикулярно к направлению
                активных ветров (Казалинский, Сырдариинский, Шиелийский,
                Жанакорганский административные районы)
              </p>
            </td>
          </tr>
          <tr>
            <td>
              <p>8-4</p>
            </td>
            <td>
              <p>
                Проведение комплекса водохозяйственно-эксплуатационных
                мероприятий (плановое водопользование, рациональная организация
                расходов воды, создание водонакопителей у артезианских скважин,
                предотвращение водопотерь и фильтрации) на пастбищах территории
                Аральского, Казалинского, Кармакшынского, Жалагашского,
                Жанакорганского, Сырдариинского, Шиелийского административных
                районов
              </p>
            </td>
          </tr>
          <tr>
            <td>
              <p>8-5</p>
            </td>
            <td>
              <p>
                Рекомендуется перевод пашни с солонцеватостью и солонцеватой
                пятнистостью более 30 % в категорию пастбищных угодий с коренным
                улучшением. Отдельные однородные участки слабозасоленных земель
                могут быть использованы под огородные культуры (Казалинский,
                Сырдариинский, Шиелийский районы)
              </p>
            </td>
          </tr>
          <tr>
            <td>
              <p>8-6</p>
            </td>
            <td>
              <p>
                Провести и противоэрозионую и противодифляциоиную организацию
                орошаемых земель, которая предусматривает выделение
                севооборотных массивов с учетом эродированности и
                дефлированность почв (Аральский, Казалинский, Сырдариинский,
                Шиелийский административные районы)
              </p>
            </td>
          </tr>
          <tr>
            <td>
              <p>8-7</p>
            </td>
            <td>
              <p>
                Осуществить инвентаризацию и картирование эрозионно-опасных
                земель в административных районах - Аральский, Казалинский,
                Кармакш ы некий, Жалагашский, Жанакорганский, Сырдариинский,
                Шиелийский
              </p>
            </td>
          </tr>
          <tr>
            <td>
              <p>8-9</p>
            </td>
            <td>
              <p>
                Лесомелиоративные противоэрозионные мероприятия (создание
                водорегулирующих лесополос в местах проведения земледельческих
                работ) (Аральский, Казалинский, Кармакшынский, Жалагашский,
                Жанакорганский, Сырдариинский, Шиелийский административные
                районы)
              </p>
            </td>
          </tr>
          <tr>
            <td>
              <p>8-10</p>
            </td>
            <td>
              <p>
                Организация и обеспечение защиты, воспроизводства лесов,
                регулирование лесопользования и лесоустройства государственного
                лесного фонда на всей территории Кызылординской ПС С. Провести
                химическую обработку саксаульников (Казалинский, Сырдариинский,
                Шиелийский районы)
              </p>
            </td>
          </tr>
          <tr>
            <td>
              <p>8-11</p>
            </td>
            <td>
              <p>
                Разработка мероприятий по снижению пожарной опасности лесов
                (саксауловых и тугайных) (Аральский и Казалинский
                административные районы)
              </p>
            </td>
          </tr>
          <tr>
            <td colSpan="2" width="644">
              <p>
                <b>Организация ландшафтно-экологического мониторинга</b>
              </p>
            </td>
          </tr>
          <tr>
            <td>
              <p>13-1</p>
            </td>
            <td>
              <p>
                Благоустройство населенных пунктов (упорядочение и организация
                локальных водопроводов и канализации, увеличение площади
                многолетних насаждений, ликвидация мест концентрации твердых и
                жидких отходов)
              </p>
            </td>
          </tr>
          <tr>
            <td>
              <p>13-2</p>
            </td>
            <td>
              <p>
                Провести инвентаризацию бесхозных бытовых отходов в близи пос.
                Айтеке би Казалинского района
              </p>
            </td>
          </tr>
          <tr>
            <td>
              <p>13-3</p>
            </td>
            <td>
              <p>
                Соблюдение режимов использования особо охраняемых территорий в
                Аральском районе (Барсакельмесский государственный природный
                заповедник), в Шиелийском районе (Каргалынский государственный
                природный заказник)
              </p>
            </td>
          </tr>
          <tr>
            <td>
              <p>13-4</p>
            </td>
            <td>
              <p>
                Проведения работ по благоустройству и озеленению населенных
                пунктов на территории всей природно-сельскохозяйственной системы
              </p>
            </td>
          </tr>
        </table>
      </div>
    </div>
  )
}

export default Recs3
