import * as FA from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom/cjs/react-router-dom'
import { Button } from 'rsuite'

const Recs2 = () => {
  const { t, i18n } = useTranslation()

  return (
    <div>
      <Link to='/recs'><Button appearance='primary'><FontAwesomeIcon icon={FA.faHome} /></Button></Link><h1 className='title-h1'>Рекомендации</h1>
      <hr />
      <div className='about-text'>
        <p>Для борьбы с опустыниванием и восстановления деградированных земель можно реализовать несколько рекомендательных стратегий.</p>
        <p><b>1-Разработка и развитие региональных схем</b> комплексного социально-экономического развития и охраны окружающей среды для аридных территорий в целях борьбы с опустыниванием, являясь важной составной частью регионального плана, представляют собой предпроектные научно обоснованные документы, в которых на основе детального анализа природных, демографических, экономических и социальных условий рассматриваемых территорий определяются основные проблемы их дальнейшего развития и разрабатывается комплекс взаимоувязанных мероприятий, обеспечивающих наиболее эффективное развитие хозяйства, социальной сферы, охраны окружающей среды в пределах определенных территорий и ориентированных на борьбу с опустыниванием.</p>
        <p><b>2-Использование территориальных, комплексных, эколого-экономических и системных подходов при поиске решении</b> междисциплинарного, комплексного и целенаправленного характера проблем, решаемых региональными схемами, делает необходимым использовать территориальный, комплексный, эколого-экономический, системный и программно-целевой подходы.</p>
        <p>Территориальный подход к разработке региональных схем состоит в том, что все предложения привязываются к конкретной территории. Комплексный подход как по вертикали, так и по горизонтали обеспечивает наибольшую полноту проектных решений и служит надежной гарантией против возможных трудностей. Эколого-экономического подход, т.е. рассмотрение “на равных” как социально-экономической, так и естественной (экологической) составляющих того или иного региона. Системный предполагающий рассмотрение того или иного региона как системы, также необходим, т.к. любой регион представляет собой систему, определяемую взаимодействием двух крупных субсистем - природной и антропогенной. Программно-целевой подход позволяет на основе комплексного анализа природных условий, населения, хозяйства и выбора альтернативы развития представить и сформулировать необходимые предложения в виде целенаправленной программы, имеющей четко очерченные цели, ясную временную ориентацию, полную информационную базу.</p>
        <p><b>3-Определение эффективных методов и методик.</b> В процессе разработки программ и стратегий применяются специализированные методы, позволяющие принимать оптимальные решения. При разработке комплексных программ применяются как традиционные, так и прогрессивные методы исследования, планирования и прогнозирования. Они обеспечивают всесторонний и научно обоснованный процесс принятия решений для достижения эффективных результатов.</p>
        <p><b>4-Выделение регионов (картографирование, создание специализированных карт),</b> подверженных опустыниванию следует производить на основе достаточно детальных исследований по основным группам критериев: природным, экономическим и социальным. Данный процесс особенно важно, так как реализацию мероприятии региональной схемы в основном проводят региональные и местные органы власти. Главным природным критерием является современное распространение процессов опустынивания, включая территории потенциально находящиеся в опасном состоянии, т.е. в пределах которых отчетливо видны проявления этих процессов. Выбор региона в качестве первоочередного объекта планирования должен быть обоснован на основе рассмотрения обшей ситуации с опустыниванием в регионах и в ряде случаев путем сопоставления нескольких потенциальных объектов между собой (по силе проявления процессов опустынивания, по важности данного региона в системе народного хозяйства страны и т. д.).</p>
        <p><b>5-Составление комплексной цели и задачи устойчивого развития региона.</b> Цели и конструктивные задачи выражают степень решения поставленной проблемы. С их помощью одновременно может быть дана генерализованная оценка путей и средств достижения определенного уровня экономико-социальных условий, состояния окружающей среды и т. д. Цели решения проблемы борьбы с опустыниванием посредством социально-экономического развития соответствующих регионов должны соответствовать общенациональным целям развития страны, а также объективным социально-экономическим закономерностям ее развития.</p>
        <p><b>6-Анализ проблем развития региона</b> включает в себя широкий круг вопросов, связанных с функционированием хозяйства региона, его природными особенностями, демографической ситуацией, характером землепользования, уровнем индустриализации и урбанизации и т. д. Наиболее важные составляющие этой части работы - анализ природных условий и ресурсов региона с точки зрения наиболее рационального их использования и борьбы с опустыниванием в конкретных условиях; анализ неблагоприятных физико-географических и физико- геологических явлений (в том числе связанных с антропогенными воздействиями на природную среду); комплексная оценка территории с точки зрения степени ее благоприятности для важнейших видов человеческой деятельности; анализ материальных и трудовых ресурсов; системы хозяйства и землепользования; анализ производственной, социальной и технической инфраструктуры - расселения, общественного обслуживания и т. д.</p>
        <p><b>7-Составление прогнозов и альтернатив развития региона.</b> Одной из ключевых задач прогноза является оценка общей потребности в ресурсах — сырьевых, материальных, технологических, энергетических, транспортных, трудовых, финансовых и информационных — необходимых для обеспечения планируемого объёма производства, реализации и потребления продукции, как в натуральном, так и в стоимостном выражении, а также для выполнения запланированных мероприятий. Прогноз может быть составлен в нескольких вариантах, учитывающих различные способы решения выявленных проблем, с оценкой предполагаемых показателей в виде наиболее вероятных максимальных и минимальных значений.</p>
        <p><b>8-Разработка комплексных программ по предотвращению развития процессов опустынивания в ПСС.</b> Разработка системы конкретных конструктивных мероприятий, а также окончательная увязка целей и ресурсов осуществляется в рамках долгосрочных комплексных программ — плановых документов, которые определяют законченные во времени и пространстве комплексы производственных, технических, социальных, экологических, организационных и других мероприятий, направленных на реализацию одной или нескольких целей.</p>
        <p><b>9-Создание информационной базы и системы мониторинга.</b> Разработка схем или стратегии устойчивого развития регионов, как и социально-экономическое планирование в целом, должна опираться на достаточно полную и достоверную базу и систему мониторинга, т.е. слежения и контроля за изменениями, происходящими в окружающей среде (прежде всего процессами опустынивания), включая прямые и обратные реакции в результате воздействия на нее народнохозяйственного комплекса региона.</p>
      </div>
    </div>
  )
}

export default Recs2
