import L from 'leaflet'
import 'leaflet-imageoverlay-rotated'
import 'leaflet-toolbar'
import React, { useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Table } from 'rsuite'
import MapControls from '../../../map/MapControls'
import GenericPage from '../../components/generic/GenericPage'
import tiles from '../../components/generic/Tiles'

const MapBass = () => {
  const [sel, setSel] = useState()

  const [mapType, setMap] = useState(
    'https://tile.openstreetmap.org/{z}/{x}/{y}.png'
  )

  const { t, i18n } = useTranslation()

  const [isLoading, setIsLoading] = useState(false)

  const mapRef = useRef(null)
  const paneRef = useRef(null)
  const drawnLayerRef = useRef(null)
  const showLayerRef = useRef(null)
  const tileLayerRef = useRef(null)
  const searchGeometryRef = useRef(null)

  const [XLSX, setXLSX] = useState()
  const [columns, setColumns] = useState()
  const [selType, setType] = useState()
  const [selName, setName] = useState()

  let map

  useEffect(() => {
    try {
      map = L.map('map', {
        center: [49.237257, 69.9804824],
        zoom: 5,
        minZoom: 1,
        zoomControl: false,
        attributionControl: false
      })
      L.tileLayer(mapType, {
        subdomains: ['mt0', 'mt1', 'mt2', 'mt3'],
        maxZoom: 18,
        id: 'baselayer'
      }).addTo(map)
      L.control
        .attribution({
          position: 'bottomright'
        })
        .addTo(map)

      searchGeometryRef.current = L.featureGroup().addTo(map)
      showLayerRef.current = L.featureGroup().addTo(map)
      drawnLayerRef.current = L.featureGroup().addTo(map)
      tileLayerRef.current = L.featureGroup().addTo(map)

      mapRef.current = map
      const topPane = mapRef?.current.createPane(
        'leaflet-top-pane',
        mapRef.current.getPanes().mapPane
      )
      paneRef.current = topPane

      MapControls(mapRef.current, t)
      mapRef.current.invalidateSize(9)
    } catch (e) { }
  }, [])

  useEffect(() => {
    if (sel) {
      fetch(`./hydro/${sel}.json`).then(async (e) => {
        const res = await e.json()
        setXLSX(res)
        const uniqueKeys = new Set()
        res.forEach((obj) => {
          Object.keys(obj).forEach((key) => uniqueKeys.add(key))
        })
        const uniqueKeysArray = Array.from(uniqueKeys)

        setColumns(uniqueKeysArray)
      })
    }
  }, [sel])

  useEffect(() => {
    searchGeometryRef.current.clearLayers()
    fetch('../bassein_iur2.json').then(async (response) => {
      const json = await response.json()
      json.features.forEach((f) => {
        const mask = L.geoJSON(f)
        const name = f.properties?.name

        mask.bindTooltip(name)

        mask.addEventListener('mouseover', () => mask.openTooltip())

        mask.addEventListener('click', () => {
          setSel(f.properties.code)
        })
        searchGeometryRef.current.addLayer(mask)
      })
    })
  }, [])

  const ext = <div className="bass">
    <h3>Балхаш-Алакольский:</h3>
    <h4
      className="selectable"
      style={sel === 'ile' ? { color: 'blue' } : {}}
      onClick={() => setSel('ile')}
    >
      &gt; Бассейн р. Иле
    </h4>
    <h4
      className="selectable"
      style={sel === 'ile-alatau' ? { color: 'blue' } : {}}
      onClick={() => setSel('ile-alatau')}
    >
      &gt; Бассейн рек Иле Алатау
    </h4>
    <h4
      className="selectable"
      style={sel === 'jetisu-alatau' ? { color: 'blue' } : {}}
      onClick={() => setSel('jetisu-alatau')}
    >
      &gt; Бассейн рек Жетысу Алатау
    </h4>
    <h3
      className="selectable"
      style={sel === 'shu-talas' ? { color: 'blue' } : {}}
      onClick={() => setSel('shu-talas')}
    >
      Шу-Таласский
    </h3>
    <h3
      className="selectable"
      style={sel === 'aral-syr' ? { color: 'blue' } : {}}
      onClick={() => setSel('aral-syr')}
    >
      Арало-Сырдарьинский
    </h3>
  </div>

  return (
    <>
      <GenericPage mapRef={mapRef} title="Бассейны" tiles={tiles.back('db-hydro')} ext={ext} />
      {sel && (
        <div className="xlsx-viewer">
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center'
            }}
          >
          </div>
          {columns &&
            <Table
              data={XLSX}
              virtualized
              // affixHeader
              height={300}
            >
              {columns.map((c, key) => {
                return (
                  <Table.Column key={key} flexGrow={1} ho align="center">
                    <Table.HeaderCell>
                      {/* <Whisper trigger="hover" speaker={<Tooltip>{c}</Tooltip>}> */}
                      <span title={c}>{c}</span>
                      {/* </Whisper> */}
                    </Table.HeaderCell>

                    <Table.Cell dataKey={c} />
                  </Table.Column>
                )
              })}
            </Table>
          }
        </div>
      )}
    </>
  )
}
export default MapBass
