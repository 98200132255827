import * as FA from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom/cjs/react-router-dom'
import { Button } from 'rsuite'

const Recs1 = () => {
  const { t, i18n } = useTranslation()

  return (
    <div>
      <Link to='/recs'><Button appearance='primary'><FontAwesomeIcon icon={FA.faHome} /></Button></Link><h1 className='title-h1'>Требования</h1>
      <hr />
      <div className='about-text'>
        <p><i>Требование 1 - Полное ландшафтное исследование для планирования и использования природных и земельных ресурсов,</i> которые состоять из следующих этапов:</p>
        <p>- Анализ ландшафта - комплексное изучение свойств ландшафта, морфологических структур и пространственной дифференциации различных типов ППС и в разных масштабах. </p>
        <p>- Диагностика ландшафта - направлена на количественную оценку корреляции между зональными фоновыми ландшафтами, характеризующимися их природными и климатическими условиями, и изменениями, вызванными сельскохозяйственной деятельностью. </p>
        <p>- Прогнозирование ландшафта - прогнозирование будущих изменений в региональных ландшафтах при различных сценариях развития ПСС. </p>
        <p><i>Требования 2 -  Зонировании и использовании земель ПСС:</i> при зонировании и использовании ПСС должны быть обеспечены экологическая безопасность и качественное состояние земель, производить зонирование по степени экологического неблагополучия, определение уровня химического загрязнения земель, учитывать состояние растительности, провести инвентаризацию земель и выявление неиспользуемых, нерационально используемых земель.</p>
        <p><i>Требования 3 - Рациональное природопользование для предотвращения последствия пагубных внешних (экзогенных) процессов.</i> Главное требование в организации ПСС — поддержание земельно-ландшафтного равновесия, что включает оптимальное соотношение пашни, лесов, болот и других угодий. Проводить объязательные мероприятия по предотвращению водной эрозии, обвально-осыпных, оползневых и селевых процессов, суффозии, дефляции, комплексной денудации засоления, заболачивания.</p>
        <p><i>Требования 4 - Охрана сельскохозяйственных угодий</i> должны быть закреплены в локальных актах, регулирующих внутрихозяйственную деятельность, и входить в состав прав и обязанностей сельскохозяйственных предприятий. Правовые нормы, касающиеся сельскохозяйственной деятельности, должны содержать положения по охране земель.</p>
        <p><i>Требования 5 - Разработка и реализация на региональном и локальном уровнях типологической ландшафтной классификации ПСС.</i> Подобная классификация позволяет перейти к изучению, анализу, оценке динамических тенденции природных и антропогенно-обусловленных (в том числе сельскохозяйственным производством) процессов в различных типах земель, в целях определения и выбора возможных вариантов схем землепользования и землеустройства, способствующих формированию устойчивости ПСС.</p>
      </div>
    </div>
  )
}

export default Recs1
