import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import * as API from '../http/API'
import { Alert, Button, Input, Modal, SelectPicker } from 'rsuite'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import * as FA from '@fortawesome/free-solid-svg-icons'
import RichTextEditor from 'react-rte'
import { Link } from 'react-router-dom/cjs/react-router-dom'

const SciBase = () => {
  const { t, i18n } = useTranslation()

  const [ukey, update] = useState(0)
  const [postList, setPosts] = useState()
  const [categories, setCategories] = useState()
  const [dataPub, setDataPub] = useState({ contentRTF: RichTextEditor.createEmptyValue() })
  const [open, setOpen] = useState(false)

  const handleOpen = () => setOpen(true)
  const handleClose = () => setOpen(false)

  const fetchNews = () => {
    try {
      API.getCategories().then((r) => {
        API.getPosts().then(p => {
          setPosts(p.data.results)
        })
        const arr = r.data.results.reduce((cat, item) => {
          cat[item.id] = item
          return cat
        }, {})
        setCategories(arr)
      }).catch(e => { console.log(e) })
    } catch (e) {
      console.log(e)
      Alert.error(t('error.noconnection'))
    }
  }
  const publishNews = () => {
    try {
      dataPub.content = dataPub?.contentRTF.toString('html')
      API.addPost(dataPub)
        .then((r) => {
          setDataPub({ contentRTF: RichTextEditor.createEmptyValue() })
          handleClose()
          Alert.success('Успешно опубликовано!')
          update(ukey + 1)
        })
        .catch(e => { console.log(e) })
    } catch (e) {
      console.log(e)
      Alert.error(t('error.noconnection'))
    }
  }

  useEffect(() => {
    fetchNews()
  }, [ukey])

  return (
    <div>
      <Link to='/'><Button appearance='primary'><FontAwesomeIcon icon={FA.faHome} /></Button></Link><h1 className='title-h1'>Научно-методологические основы</h1>
      <hr />
      <div className='news-wrap'>
        <Button onClick={handleOpen}><h5>+ Опубликовать</h5></Button>
        <br />
        {postList?.map(n =>
          <div key={n?.title} className='news-item'>
            <h4>{n.title}</h4>
            <hr />
            <RichTextEditor readOnly value={RichTextEditor.createValueFromString(n.content, 'html')} />
            {/* <hr />
            <h6>Вложения:</h6>
            <ul>
              {n.attachments.map((attachment, index) => (
                <li key={index}>
                  <a href={attachment.blob} download={attachment.name}>{attachment.name}</a>
                </li>
              ))}
            </ul> */}
            {n.category && <p>Категория: {categories[n?.category].name}</p>}
            <p>Автор: {n?.author}</p>
          </div>
        )}
      </div>
      <Modal show={open} onHide={handleClose} style={{ width: '100%' }}>
        <Modal.Header>
          <Modal.Title>Публикация</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Input placeholder='Заголовок' value={dataPub?.title} onChange={e => setDataPub({ ...dataPub, title: e.slice(0, 80) })} />
          {categories && <SelectPicker searchable={false} placeholder='Категория' data={Object.values(categories).map(e => ({ value: e.id, label: e.name }))} value={dataPub?.category} onChange={e => setDataPub({ ...dataPub, category: e })} style={{ width: '100%' }}/>}
          {/* <Input componentClass="textarea" rows={5} placeholder="Содержание (до 1000 символов)" value={dataPub?.content} onChange={e => setDataPub({ ...dataPub, content: e.slice(0, 1000) })}/> */}
          <RichTextEditor
            value={dataPub?.contentRTF}
            onChange={e => setDataPub({ ...dataPub, contentRTF: e })}
            placeholder='Содержание'
          />
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={publishNews} appearance='primary'>
            Опубликовать
          </Button>
          <Button onClick={handleClose} appearance='subtle'>
            Отменить
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  )
}

export default SciBase
