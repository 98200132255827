import GenericPage from '../../components/generic/GenericPage'
import tiles from '../../components/generic/Tiles'

const MapSentinel = () => {
  const ext = <p className='mobile-warn'>К сожалению, данная страница не поддерживается на мобильных устройствах. Пожалуйста, воспользуйтесь ПК-версией сайта для полного доступа к функционалу!</p>
  return (
    <GenericPage frame="https://rsgiskaznu.maps.arcgis.com/apps/mapviewer/index.html?webmap=628916311d2047d38e01dcfe2996903c" title="Sentinel" tiles={tiles.back('res-lu')} frame_offset={60} ext={ext}/>
  )
}
export default MapSentinel
