const tiles = {
  main: [
    {
      name: 'Научно-методологические основы',
      to: '/scibase',
      font: 15,
      style: 'tourisme'
    },
    {
      name: 'База данных',
      to: '/db',
      font: 15,
      style: 'main'
    },
    {
      name: 'Данные ДЗЗ',
      to: '/sensing',
      font: 15,
      style: 'geosciences'
    },
    {
      name: 'Полевые исследования',
      to: '/field',
      font: 15,
      style: 'emwelt'
    },
    {
      name: 'Картографирование ПСС',
      to: '/map',
      font: 15,
      style: 'cadastre_hertzien'
    },
    {
      name: 'Рекомендации и мероприятия',
      to: '/recs',
      font: 15,
      style: 'urban_farming'
    },
    {
      name: 'Семинары и тренинги',
      to: '/sem',
      font: 15,
      style: 'at'
    }
  ],
  db: [
    {
      name: '<< Назад',
      to: '/',
      style: 'return'
    },
    {
      name: 'Гидрология',
      to: '/db-hydro',
      style: 'pag'
    },
    {
      name: 'Метеорология',
      to: '/db-meteo',
      font: 21,
      style: 'atlas_demographique'
    },
    {
      name: 'Сельское хозяйство',
      to: '/db-farm',
      style: 'agriculture'
    }
  ],
  hydro: [
    {
      name: '<< Назад',
      to: '/db',
      style: 'return'
    },
    {
      name: 'Гидропосты',
      to: '/db-hydro-posts',
      style: 'pag',
      font: 23
    },
    {
      name: 'Водохозяйственные бассейны',
      to: '/db-hydro-bass',
      font: 18,
      style: 'pag'
    }
  ],
  recs: [
    {
      name: '<< Назад',
      to: '/',
      style: 'return'
    },
    {
      name: 'Требования',
      to: '/recs-1',
      style: 'urban_farming',
      font: 23
    },
    {
      name: 'Рекомендации',
      to: '/recs-2',
      style: 'urban_farming',
      font: 20
    },
    {
      name: 'Мероприятия',
      to: '/recs-3',
      style: 'urban_farming',
      font: 20
    }
  ],
  res: [
    {
      name: '<< Назад',
      to: '/',
      style: 'return'
    },
    // {
    //   name: 'GEE',
    //   to: '/gee',
    //   style: 'at'
    // },
    {
      name: 'Индексы',
      to: '/res-indices',
      style: 'at'
    },
    {
      name: 'Land Use and Land Cover Map',
      to: '/res-lu',
      style: 'lenoz',
      font: 20
    }
  ],
  field: [
    {
      name: '<< Назад',
      to: '/',
      style: 'return'
    },
    {
      name: 'Фотогалерея',
      to: '/field-img',
      style: 'main',
      font: 24
    },
    {
      name: 'Карта маршрутов',
      to: '/field-points',
      style: 'lenoz'
    }
  ],
  mapping: [
    {
      name: '<< Назад',
      to: '/',
      style: 'return'
    },
    {
      name: 'Карта современных ландшафтов',
      to: '/map-ls',
      style: 'return',
      font: 19
    },
    {
      name: 'Карта устойчивости',
      to: '/map-nc',
      style: 'return',
      font: 20
    },
    {
      name: 'Карта природоохранных мероприятий',
      to: '/map-np',
      style: 'return',
      font: 15
    },
    {
      name: 'Карта сельскохозяйственной освоенности природных комплексов',
      to: '/map-rn',
      style: 'return',
      font: 15
    },
    {
      name: 'Карта функционального зонирования',
      to: '/map-fs',
      style: 'return',
      font: 15
    },
    {
      name: 'Карта степени деградации',
      to: '/map-dd',
      style: 'return',
      font: 25
    }
  ],
  landuse: [
    {
      name: '<< Назад',
      to: '/sensing',
      style: 'return'
    },
    {
      name: 'Sentinel',
      to: '/res-lu-sentinel',
      style: 'main'
    },
    {
      name: 'Landsat',
      to: '/res-lu-landsat',
      style: 'main'
    }
  ],
  back: (where) => [{
    name: '<< Назад',
    to: `/${where}`,
    style: 'return'
  }]
}

export default tiles
