import { Link } from 'react-router-dom/cjs/react-router-dom'
import { Button } from 'rsuite'

const SideBase = (props) => {
  return (
    <div id="sidebar" className="open">
      <div className="un-list">
          <img src='logo2.png'/>
          <img src='logo3.png'/>
      </div>
        <div className="tab-content">
                  {props.list}
        </div>
    </div>)
}

export default SideBase
