import Cookies from 'js-cookie'
import React, { useEffect } from 'react'
import { Route, BrowserRouter as Router, Switch } from 'react-router-dom'
import { refresh, validate } from './http/API'
import About from './pages/About'
import Auth from './pages/Auth'
import MapDatabase from './pages/db/MapDatabase'
import MapBass from './pages/db/hydro/MapBass'
import MapPosts from './pages/db/hydro/MapPosts'
import MapMeteo from './pages/db/MapMeteo'
import MapFImgs from './pages/field/MapFImgs'
import MapFPoint from './pages/field/MapFPoints'
import MapLS from './pages/mapping/MapLS'
import MapNew from './pages/MapNew'
import MapGEE from './pages/res/MapGEE'
import MapRES from './pages/res/MapRES'
import SciBase from './pages/SciBase'
import MapField from './pages/field/MapField'
import MapMapping from './pages/mapping/MapMapping'
import MapLU from './pages/res/landuse/MapLU'
import MapSentinel from './pages/res/landuse/MapSentinel'
import MapLandsat from './pages/res/landuse/MapLandsat'
import MapNC from './pages/mapping/MapNC'
import MapNP from './pages/mapping/MapNP'
import MapRN from './pages/mapping/MapRN'
import MapFS from './pages/mapping/MapFS'
import MapDD from './pages/mapping/MapDD'
import MapIndices from './pages/res/MapIndices'
import MapHyd from './pages/db/hydro/MapHyd'
import MapRecs from './pages/recs/MapRecs'
import Recs1 from './pages/recs/Recs1'
import Recs2 from './pages/recs/Recs2'
import Recs3 from './pages/recs/Recs3'

function App () {
  const token = Cookies.get('token')
  const startup = () => {
    if (token) {
      validate().then().catch(e => console.log(e))
    }
  }
  const authRedirect = () => Auth({ close: () => { window.location.href = '/' } })

  useEffect(() => {
    const refreshTO = setInterval(() => {
      if (Cookies.get('refresh')) {
        refresh()
      }
    }, 1000 * 60 * 4)

    startup()

    return () => {
      clearInterval(refreshTO)
    }
  }, [])
  return (
    <>
      <Router>
        <Switch>
          <Route path='/scibase' component={token ? SciBase : authRedirect} />

          <Route path='/db' component={token ? MapDatabase : authRedirect} />
          <Route path='/db-meteo' component={token ? MapMeteo : authRedirect} />
          <Route path='/db-farm' component={token ? MapDatabase : authRedirect} />
          <Route path='/db-hydro' component={token ? MapHyd : authRedirect} />

          <Route path='/db-hydro-bass' component={token ? MapBass : authRedirect} />
          <Route path='/db-hydro-posts' component={token ? MapPosts : authRedirect} />

          <Route path='/field' component={token ? MapField : authRedirect} />
          <Route path='/field-points' component={token ? MapFPoint : authRedirect} />
          <Route path='/field-img' component={token ? MapFImgs : authRedirect} />
          <Route path='/field-waypoint' component={token ? MapFPoint : authRedirect} />

          <Route path='/map' component={token ? MapMapping : authRedirect} />
          <Route path='/gee' component={token ? MapGEE : authRedirect} />
          <Route path='/map-ls' component={token ? MapLS : authRedirect} />
          <Route path='/map-nc' component={token ? MapNC : authRedirect} />
          <Route path='/map-np' component={token ? MapNP : authRedirect} />
          <Route path='/map-rn' component={token ? MapRN : authRedirect} />
          <Route path='/map-fs' component={token ? MapFS : authRedirect} />
          <Route path='/map-dd' component={token ? MapDD : authRedirect} />

          <Route path='/sensing' component={token ? MapRES : authRedirect} />
          <Route path='/res-indices' component={token ? MapIndices : authRedirect} />
          <Route path='/res-lu' component={token ? MapLU : authRedirect} />
          <Route path='/res-lu-sentinel' component={token ? MapSentinel : authRedirect} />
          <Route path='/res-lu-landsat' component={token ? MapLandsat : authRedirect} />

          <Route path='/recs' component={token ? MapRecs : authRedirect} />
          <Route path='/recs-1' component={token ? Recs1 : authRedirect} />
          <Route path='/recs-2' component={token ? Recs2 : authRedirect} />
          <Route path='/recs-3' component={token ? Recs3 : authRedirect} />

          <Route path='/about' component={About} />
          <Route exact path='*' component={MapNew} />
        </Switch>
      </Router>
    </>
  )
}

export default App
