import L from 'leaflet'
import 'leaflet-imageoverlay-rotated'
import 'leaflet-toolbar'
import { range } from 'lodash'
import React, { useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { SelectPicker } from 'rsuite'
import MapControls from '../../../map/MapControls'
import GenericPage from '../../components/generic/GenericPage'
import tiles from '../../components/generic/Tiles'

const MapLandsat = () => {
  const [sel, setSel] = useState(2000)

  const [mapType, setMap] = useState('https://tile.openstreetmap.org/{z}/{x}/{y}.png')

  const { t, i18n } = useTranslation()

  const mapRef = useRef(null)
  const paneRef = useRef(null)
  const drawnLayerRef = useRef(null)
  const showLayerRef = useRef(null)
  const tileLayerRef = useRef(null)
  const searchGeometryRef = useRef(null)

  let map

  useEffect(() => {
    try {
      map = L.map('map', {
        center: [49.237257, 69.9804824],
        zoom: 5,
        minZoom: 1,
        zoomControl: false,
        attributionControl: false
      })
      L.tileLayer(mapType, {
        subdomains: ['mt0', 'mt1', 'mt2', 'mt3'],
        maxZoom: 18,
        id: 'baselayer'
      }).addTo(map)
      L.control.attribution({
        position: 'bottomright'
      }).addTo(map)

      searchGeometryRef.current = L.featureGroup().addTo(map)
      showLayerRef.current = L.featureGroup().addTo(map)
      drawnLayerRef.current = L.featureGroup().addTo(map)
      tileLayerRef.current = L.featureGroup().addTo(map)

      L.tileLayer(`https://tiles-ap1.arcgis.com/Iiwz1ZQng7a0HggY/arcgis/rest/services/LULC_Landscape_${sel}_dat/MapServer/WMTS/tile/1.0.0/LULC_Landscape_${sel}_dat/default/default028mm/{z}/{y}/{x}`, {
        minNativeZoom: 6,
        maxNativeZoom: 13,
        id: 'tiles'
      }).addTo(tileLayerRef.current)

      mapRef.current = map
      const topPane = mapRef?.current.createPane('leaflet-top-pane', mapRef.current.getPanes().mapPane)
      paneRef.current = topPane

      MapControls(mapRef.current, t)
      mapRef.current.invalidateSize(9)
    } catch (e) {

    }

    const legend = document.createElement('img')
    legend.id = 'legend'
    legend.src = 'legend.png'

    legend.style.position = 'fixed'
    legend.style.height = 500
    legend.style.width = '40%'
    legend.style.maxWidth = '300px'
    legend.style.right = 0
    legend.style.bottom = 0
    legend.style.zIndex = 1

    document.getElementById('root').appendChild(legend)

    return () => {
      document.getElementById('legend').remove()
    }
  }, [])

  useEffect(() => {
    tileLayerRef.current.clearLayers()
    if (sel) {
      L.tileLayer(`https://tiles-ap1.arcgis.com/Iiwz1ZQng7a0HggY/arcgis/rest/services/LULC_Landscape_${sel}_dat/MapServer/WMTS/tile/1.0.0/LULC_Landscape_${sel}_dat/default/default028mm/{z}/{y}/{x}`, {
        minNativeZoom: 6,
        maxNativeZoom: 13,
        id: 'tiles'
      }).addTo(tileLayerRef.current)
    }
  }, [sel])

  const DB = {
    years: range(2000, 2020, 5).map(y => ({ label: y, value: y }))
  }

  const ext =
  <li className='selects'>
    <SelectPicker placeholder='Год' data={DB.years} searchable={false} value={sel} onChange={setSel}/>
  </li>

  return (
    <GenericPage mapRef={mapRef} title="Landsat" tiles={tiles.back('res-lu')} ext={ext}/>
  )
}
export default MapLandsat
